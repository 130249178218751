import { useEffect, useState } from "react";
import ListNew from "../../../components/ListNew";
import NewWrapper from "./NewWrapper";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const itemsPerPage = 10;

const New = () => {
	let { slug } = useParams();

	const [news, setNews] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);
	const [totalItems, setTotalItems] = useState(1);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		document.title = "Tin tức";
		getAllNews(slug, 1);
	}, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getAllNews(slug, currentPage);
	}, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

	const getAllNews = async (cateSlug, page) => {
		setIsLoading(true);
		await axios
			.get(`/post/list`, {
				params: {
					cateSlug: cateSlug,
					page: page,
					limit: itemsPerPage,
				},
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setTotalItems(res.data.itemCount);
					setNews(res.data.posts);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<NewWrapper>
			<ListNew
				posts={news}
				isLoading={isLoading}
				itemsCount={totalItems}
				itemsPerPage={itemsPerPage}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				isPagiantion={true}
			/>
		</NewWrapper>
	);
};

export default New;
