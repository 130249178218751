import React, { useState } from "react";
import { Link } from "react-router-dom";
import Icon from "../../components/Icon";
import { Col, Row } from "react-bootstrap";

const DropdownMenu = (children) => {
    const items = Array.from(children.children);
    return (
        <Col>
            {items.map((item) => {
                return (
                    <Row>
                        <Link
                            to={item.link}
                            className="navbar-text menu-dropdown-item"
                        >
                            {item.title}
                        </Link>
                    </Row>
                );
            })}
        </Col>
    );
};

const SidebarItem = ({ item, setShowSideBar }) => {
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    const toggleShowDropdown = () => {
        setDropdownVisible(!isDropdownVisible);
    }
    if (item.children === undefined) {
        return (
            <div key={item.id}>
                <div
                    style={{
                        fontWeight: 600,
                        textTransform: "uppercase",
                    }}
                    className="d-flex justify-content-between align-items-center mb-2"
                >
                    <Link
                        to={item.link}
                        style={{ textDecoration: "none" }}
                        onClick={() => setShowSideBar(false)}
                    >
                        <div className="navbar-text">
                            {item.title}
                        </div>
                    </Link>
                </div>
            </div>
        );
    }
    return (
        <div key={item.id}        >
            <div
                style={{
                    fontWeight: 600,
                    textTransform: "uppercase",
                }}
                className="d-flex justify-content-between align-items-center mb-2"
            >
                <Link
                    to={item.link}
                    style={{ textDecoration: "none" }}
                    onClick={() => setShowSideBar(false)}
                >
                    <div className="navbar-text">
                        {item.title}
                    </div>
                </Link>
                <div
                    onClick={toggleShowDropdown}
                    className="dropdown-icon"
                >
                    <Icon
                        icon={isDropdownVisible ? "angle-up" : "angle-down"}
                    />
                </div>
            </div>
            {isDropdownVisible && <DropdownMenu children={item.children} />}
        </div>
    );
}

export default SidebarItem;