import { Button, Container, Form } from "react-bootstrap";
import DataTables from "../../../components/DataTables";

import Icon from "../../../components/Icon";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DateFormat from "../../../components/DateFormat";
import ImageURL from "../../../components/ImageURL";

import swal from "sweetalert";

import ProductAddModal from "./ProductAddModal";
import ProductEditModal from "./ProductEditModal";
import Loading from "../../../components/Loading";

const Product = () => {
	const [products, setProducts] = useState([]);
	const [showAddModal, setShowAddModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [keyword, setKeyword] = useState("");

	useEffect(() => {
		getAllProducts("");
	}, []);

	// Api
	const getAllProducts = async (keyword) => {
		setIsLoading(true);
		await axios
			.get(`/product/show`, {
				params: { keyword: keyword },
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setProducts(
						res.data.products.map((product) => {
							return {
								...product,
								imgPaths: product.imgPaths
									? product.imgPaths.split(",")
									: [],
							};
						}),
					);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deleteProduct = async (productId) => {
		await axios
			.delete(`/product/destroy`, {
				params: {
					id: productId,
				},
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					toast(res.data.message, {
						type: "success",
						autoClose: 1000,
					});

					getAllProducts("");
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	const handleDeleteProduct = (productId) => {
		swal({
			title: `Bạn muốn xóa sản phẩm này`,
			icon: "warning",
			buttons: ["Đóng", "Xóa"],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				deleteProduct(productId);
			}
		});
	};

	const handleCloseAddModal = (isReload) => {
		setShowAddModal(false);
		if (isReload) {
			getAllProducts("");
		}
	};
	const handleShowAddModal = () => {
		setShowAddModal(true);
		setSelectedProduct();
	};

	const handleCloseEditModal = (isReload) => {
		setShowEditModal(false);
		if (isReload) {
			getAllProducts("");
		}
	};
	const handleShowEditModal = (productId) => {
		setShowEditModal(true);
		setSelectedProduct(productId);
	};

	const columns = [
		{
			name: "Hình ảnh",
			style: {
				display: "flex",
				justifyContent: "center",
			},
			selector: (row) => (
				<ImageURL
					style={{
						aspectRatio: "900/600",
						height: "4rem",
						backgroundSize: "contain",
					}}
					imageURL={row.imgPaths[0]}
				/>
			),
		},
		{
			name: "Tên sản phẩm",
			selector: (row) => row.proNameVi,
			wrap: true,
		},
		{
			name: "Slug",
			selector: (row) => row.proSlug,
			wrap: true,
		},
		{
			name: "Cập nhật",
			selector: (row) => <DateFormat date={row.updatedAt} />,
		},
		{
			name: "Phương thức",
			style: {
				display: "flex",
				justifyContent: "center",
			},
			selector: (row) => (
				<>
					<Button
						size="sm"
						variant="warning me-2"
						type="button"
						onClick={() => handleShowEditModal(row.id)}
						title="Chi tiết"
					>
						<Icon icon="pencil" />
					</Button>

					<Button
						size="sm"
						variant="danger"
						type="button"
						onClick={() => handleDeleteProduct(row.id)}
						title="Xóa"
					>
						<Icon icon="trash" />
					</Button>
				</>
			),
		},
	];

	return (
		<Container fluid>
			<div
				style={{
					backgroundColor: "#26B59C",
					fontSize: "1.5rem",
					color: "white",
				}}
				className="text-center w-100 mb-3 py-3 mt-3"
			>
				Sản phẩm
			</div>

			<div className="d-block d-md-flex justify-content-between mb-3">
				<Form
					className="d-flex"
					onSubmit={(e) => {
						e.preventDefault();
						getAllProducts(keyword);
					}}
				>
					<Form.Control
						type="search"
						placeholder={"Nhập tên sản phẩm"}
						className="me-2"
						aria-label="Search"
						value={keyword}
						onChange={(e) => setKeyword(e.target.value)}
					/>
					<Button variant="outline-secondary" type="submit">
						<Icon icon="magnifying-glass" />
					</Button>
				</Form>
				<div className="mt-3 mt-md-0">
					<Button
						variant="primary"
						className="mt-2 mt-md-0"
						onClick={() => handleShowAddModal()}
					>
						<Icon icon="plus" /> Thêm mới
					</Button>
				</div>
			</div>

			{isLoading ? (
				<Loading />
			) : (
				<DataTables data={products} columns={columns} />
			)}

			{showAddModal ? (
				<ProductAddModal
					handleCloseModal={handleCloseAddModal}
					showModal={showAddModal}
				/>
			) : (
				""
			)}

			{showEditModal ? (
				<ProductEditModal
					handleCloseModal={handleCloseEditModal}
					showModal={showEditModal}
					selectedProduct={selectedProduct}
				/>
			) : (
				""
			)}
		</Container>
	);
};

export default Product;
