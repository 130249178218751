const convertToSlug = (str) => {
	if (str !== "undefined") {
		str = str.toLowerCase(); // Chuyển hết sang chữ thường
		str = str
			.normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
			.replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
		str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
		str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
		str = str.replace(/ /gi, "-");
		str = str.replace(/-----/gi, "-");
		str = str.replace(/----/gi, "-");
		str = str.replace(/---/gi, "-");
		str = str.replace(/--/gi, "-");
		//Xóa các ký tự gạch ngang ở đầu và cuối
		str = "@" + str + "@";
		str = str.replace(/@-|-@|@/gi, "");
	}

	return str;
};

export default convertToSlug;
