import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import Icon from "./Icon";
import { Link } from "react-router-dom";

const UserProfile = () => {
	const handleLogout = () => {
		localStorage.removeItem("access_token");
		window.location.href = "/dang-nhap";

		toast("Đăng xuất thành công", {
			type: "success",
			autoClose: 1000,
		});
	};

	return (
		<>
			<Dropdown className=" me-3">
				<Dropdown.Toggle
					style={{
						background: "none",
						border: "none",
						backgroundColor: "#145f38",
						color: "white",
					}}
					className="rounded-pill"
				>
					<Icon icon="user" />
					<span className="limited-text ps-2">Xin chào admin</span>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item as={Link} to={`/`}>
						<Icon className="me-2" icon="shop" />
						Trang chủ
					</Dropdown.Item>
					<Dropdown.Divider />
					<Dropdown.Item onClick={() => handleLogout()}>
						<Icon className="me-2" icon="right-from-bracket" />
						Đăng xuất
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</>
	);
};

export default UserProfile;
