import { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../../utils/yupGlobal";
import { toast } from "react-toastify";

import { Button, Form, Modal } from "react-bootstrap";

const MediaAddModal = ({ handleCloseModal, showModal }) => {
	const [isSendForm, setIsSendForm] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(Schema),
	});

	// Api
	const createNewMedia = async (data) => {
		setIsSendForm(true);

		await axios
			.post(`/video/create`, {
				videoTitleVi: data.name,
				embedCode: data.link,
				isType: "vOther",
			})
			.then((res) => {
				setIsSendForm(false);

				if (res.data.errCode === 0) {
					toast(res.data.message, {
						type: "success",
						autoClose: 1000,
					});
					handleCloseModal(true);
				} else {
					toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	return (
		<Modal
			show={showModal}
			onHide={() => handleCloseModal(false)}
			backdrop="static"
		>
			<Modal.Header closeButton={!isSendForm}>
				<Modal.Title>Thêm mới video</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form
					id="add-media-hook-form"
					onSubmit={handleSubmit((data) => {
						createNewMedia(data);
					})}
					className="mb-5"
				>
					<div className="mb-3">
						<Form.Floating>
							<Form.Control
								type="text"
								placeholder={`Tiêu đề`}
								name={`name`}
								{...register(`name`)}
							/>

							<Form.Label>
								{`Tiêu đề`}
								<i className="text-danger">*</i>
							</Form.Label>
						</Form.Floating>

						{errors[`name`] && (
							<p
								style={{
									fontSize: 13,
									color: "red",
									marginTop: 6,
								}}
							>
								{errors[`name`].message}
							</p>
						)}
					</div>

					<div className="mb-3">
						<Form.Floating>
							<Form.Control
								type="text"
								placeholder={`Link`}
								name={`link`}
								{...register(`link`)}
							/>

							<Form.Label>
								{`Link`}
								<i className="text-danger">*</i>
							</Form.Label>
						</Form.Floating>

						{errors[`link`] && (
							<p
								style={{
									fontSize: 13,
									color: "red",
									marginTop: 6,
								}}
							>
								{errors[`link`].message}
							</p>
						)}
					</div>
				</Form>
			</Modal.Body>

			<Modal.Footer>
				<Button
					type="button"
					variant="secondary"
					onClick={() => handleCloseModal(false)}
					disabled={isSendForm}
				>
					Đóng
				</Button>
				<Button
					type="submit"
					form="add-media-hook-form"
					variant="primary"
					disabled={isSendForm}
				>
					Lưu
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

const Schema = yup.object().shape({
	name: yup
		.string()
		.required("Không được bỏ trống")
		.test(
			"len",
			"Tên video không dài quá 255 kí tự",
			(val) => val.length >= 0 && val.length <= 255,
		),
	link: yup.string().required("Không được bỏ trống"),
});

export default MediaAddModal;
