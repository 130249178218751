import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../utils/yupGlobal";

import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Input from "../../components/Input";
import Icon from "../../components/Icon";

const Contact = () => {
	const [shop, setShop] = useState({});
	const [isSendForm, setIsSendForm] = useState(false);

	const {
		reset,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onChange",
		resolver: yupResolver(Schema),
	});

	useEffect(() => {
		document.title = "Liên hệ";
		getSetting();
	}, []);

	// API
	const getSetting = async () => {
		await axios
			.get(`/shop/detail`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setShop(res.data.shop);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const sendMail = async (data) => {
		setIsSendForm(true);

		await axios
			.post(`/sendMail`, {
				from: shop.shopEmail,
				to: shop.shopEmail,
				subject: "KATEC",
				fullname: data.fullname,
				phonenumber: data.phonenumber,
				email: data.email || "",
				message: data.message || "",
			})
			.then((res) => {
				setIsSendForm(false);

				if (res.data.status) {
					reset({
						fullname: "",
						email: "",
						phonenumber: "",
						message: "",
					});
					toast(
						"Đã gửi thông tin thành công, chúng tôi sẽ liên hệ với bạn sau",
						{
							type: "success",
							autoClose: 3000,
						},
					);
				} else {
					toast("Có lỗi xảy ra, vui lòng thử lại sau", {
						type: "error",
						autoClose: 2000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	//API

	return (
		<Container className="container-wrapper">
			<div className="title-layout text-dark text-decoration-none">Trụ sở chính</div>

			<iframe
				title=""
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3928.706498664805!2d105.75135877421363!3d10.041060572328492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31a0886c3ecc3e01%3A0x5a84e770728f1669!2zMTg5IMSQLiBQaGFuIEh1eSBDaMO6LCBQaMaw4budbmcgQW4gS2jDoW5oLCBOaW5oIEtp4buBdSwgQ-G6p24gVGjGoSwgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1683166794218!5m2!1svi!2s"
				width="100%"
				height="500"
				allowFullScreen=""
				loading="lazy"
				referrerPolicy="no-referrer-when-downgrade"
				className="py-3"
			/>

			<Row className="mb-3">
				<Col xs="9" md="6" className="my-3 my-md-0">
					<h4 className="text-start mb-3">Liên hệ với chúng tôi</h4>

					<div className="mb-2">
						<div className="d-inline fw-bold">Địa chỉ:&nbsp;</div>
						{"Số 189, đường Phan Huy Chú, phường An Khánh, quận Ninh Kiều, thành phố Cần Thơ"}
						{/*shop.shopAddressVi*/}
					</div>
					<div className="mb-2">
						<div className="d-inline fw-bold">Điện thoại:&nbsp;</div>
						{"0889881010"}
						{/*shop.shopPhone*/}
					</div>
					<div className="mb-2">
						<div className="d-inline fw-bold">Email:&nbsp;</div>
						{"katec.cantho@gmail.com"}
						{/*shop.shopEmail*/}
					</div>
				</Col>
				<Col xs="12" md="6">
					<Form onSubmit={handleSubmit((data) => sendMail(data))}>
						<Input
							placeholder="Họ và tên"
							name="fullname"
							register={register("fullname")}
							errors={errors.fullname}
							className="mb-3"
						/>

						<Input
							placeholder="Email"
							name="email"
							register={register("email")}
							errors={errors.email}
							className="mb-3"
						/>

						<Input
							placeholder="Số điện thoại"
							name="phonenumber"
							register={register("phonenumber")}
							errors={errors.phonenumber}
							className="mb-3"
						/>

						<Input
							as="textarea"
							rows={5}
							placeholder="Lời nhắn"
							name="message"
							register={register("message")}
							errors={errors.message}
							className="mb-3"
						/>

						<Button
							type="submit"
							size="sm"
							className="fw-bold primary-button px-3"
							style={{
								borderRadius: 0,
							}}
							disabled={isSendForm}
						>
							<Icon icon="envelope" /> Gửi
						</Button>
					</Form>
				</Col>
			</Row>
		</Container>
	);
};

const Schema = yup.object().shape({
	fullname: yup.string().required("Không được bỏ trống"),
	phonenumber: yup
		.string()
		.required("Vui lòng nhập số điện thoại")
		.phone("Số điện thoại không đúng địng dạng"),
	email: yup.string().email("Email không đúng định dạng"),
});

export default Contact;
