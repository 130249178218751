export const INTRODUCTION = "gioi-thieu";

export const PRODUCT = "san-pham";
export const PRODUCT_DETAIL = "san-pham";

// export const SOLUTION = "quy-trinh-giai-phap";
// export const SOLUTION_TYPE = "quy-trinh-giai-phap";
// export const SOLUTION_DETAIL = "quy-trinh-giai-phap";

export const TOPIC = "tin-tuc";
export const NEW = "tin-tuc";
export const NEW_DETAIL = "tin-tuc";

export const MEDIA = "tuyen-dung";

export const CONTACT = "lien-he";

// URL
export const INTRODUCTION_URL = "gioi-thieu";

export const PRODUCT_URL = "san-pham";
export const PRODUCT_DETAIL_URL = "san-pham/:slug";

// export const SOLUTION_TYPE_URL = "quy-trinh-giai-phap";
// export const SOLUTION_URL = "quy-trinh-giai-phap/:slug";
// export const SOLUTION_DETAIL_URL = "quy-trinh-giai-phap/:slug/:slug";

export const TOPIC_URL = "tin-tuc";
export const NEW_URL = "tin-tuc/:slug";
export const NEW_DETAIL_URL = "tin-tuc/:slug/:slug";

export const MEDIA_URL = "tuyen-dung";
export const MEDIA_DETAIL_URL = "tuyen-dung/:slug";

export const CONTACT_URL = "lien-he";

export const CAREER = "tuyen-dung";
export const PROJECT = "chinh-sach";