import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import Select from "react-select";

import { Button, Col, Form, Modal, Row } from "react-bootstrap";

import yup from "../../../utils/yupGlobal";
import convertToSlug from "../../../utils/slugFormatter";

import TextEditor from "../../../components/TextEditor";
import Icon from "../../../components/Icon";

const editProductFormItems = [
	{
		id: 1,
		placeholder: "Tên sản phẩm",
		name: "name",
	},
];

const ProductEditModal = ({ handleCloseModal, showModal, selectedProduct }) => {
	const [slug, setSlug] = useState("");

	const [imageId, setImageId] = useState(0);
	const [productImages, setProductImages] = useState([]);

	const [formula, setFormula] = useState("");
	const [use, setUse] = useState("");
	const [content, setContent] = useState("");
	const [guide, setGuide] = useState("");

	const [selectedCategory, setSelectedCategory] = useState();
	const [categoryOptions, setCategoryOptions] = useState([{ value: 0, label: "" }]);

	const [isSendForm, setIsSendForm] = useState(false);
	const [errMessage, setErrMessage] = useState({
		proSlug: undefined,
	});
	const [isLoading, setIsLoading] = useState(false);

	const {
		reset,
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(Schema),
	});

	useEffect(() => {
		getAllOptionCategories();
		getDetailProduct(selectedProduct);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Api
	const getAllOptionCategories = async () => {
		await axios
			.get(`/category/show`, {
				params: {
					isType: "cPro",
					isTree: 1,
				},
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					const categoryOptions = [];
					const categories = res.data.categories[0].children;

					categories.map((category) => {
						categoryOptions.push(category);

						return category.children.map((categorySub) => {
							categoryOptions.push({
								...categorySub,
								cateNameVi: "- " + categorySub.cateNameVi,
							});

							return categorySub.children.map((categorySenSub) => {
								categoryOptions.push({
									...categorySenSub,
									cateNameVi: "- - " + categorySenSub.cateNameVi,
								});

								return categorySenSub;
							});
						});
					});

					setCategoryOptions(categoryOptions);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const loadImage = (imageUrl, index) => {
		return new Promise((resolve, reject) => {
			fetch(`${process.env.REACT_APP_BACKEND_URL}${imageUrl}`).then(async (response) => {
				const contentType = response.headers.get("content-type");
				const blob = await response.blob();
				const file = new File([blob], `image${index}.png`, {
					contentType,
					type: blob.type,
				});

				resolve(file);
			});
		});
	};

	const getDetailProduct = async (productId) => {
		setIsLoading(true);
		await axios
			.get(`/product/show`, {
				params: {
					id: productId,
				},
			})
			.then(async (res) => {
				setIsLoading(false);
				if (res.data.errCode === 0) {
					reset({
						name: res.data.product.proNameVi,
						description: res.data.product.proDescVi,
					});

					setSelectedCategory(res.data.product.categoryId);

					setFormula(res.data.product.proInfoVi || "");
					setUse(res.data.product.proInfoEn || "");
					setContent(res.data.product.proContentVi || "");
					setGuide(res.data.product.proContentEn || "");

					setSlug(res.data.product.proSlug);

					if (res.data.product.imgPaths) {
						Promise.all(res.data.product.imgPaths.split(",").map((imgURL, index) => loadImage(imgURL, index)))
							.then((data) => {
								setProductImages(
									data.map((imgFile, index) => {
										return {
											id: index,
											src: imgFile,
										};
									}),
								);

								setImageId(data.length);
							})
							.catch((err) => console.log("Failed to load images", err));
					}
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const updateProduct = async (data) => {
		setIsSendForm(true);

		var bodyFormData = new FormData();
		bodyFormData.append("id", selectedProduct);
		bodyFormData.append("isStatus", "S1");
		bodyFormData.append("categoryId", selectedCategory);
		bodyFormData.append("proNameVi", data.name);
		bodyFormData.append("proDescVi", data.description || "");
		bodyFormData.append("proInfoVi", formula);
		bodyFormData.append("proInfoEn", use);
		bodyFormData.append("proContentVi", content);
		bodyFormData.append("proContentEn", guide);

		bodyFormData.append("proSlug", slug);
		productImages.forEach((imageFile) => {
			bodyFormData.append("proImages", imageFile.src);
		});

		await axios({
			method: "put",
			url: "product/update",
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then((res) => {
				setIsSendForm(false);

				if (res.data.errCode === 0) {
					toast(res.data.message, {
						type: "success",
						autoClose: 1000,
					});
					handleCloseModal(true);
				} else {
					setErrMessage(res.data.message);
					toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	const handleUploadImage = (event) => {
		setImageId(imageId + 1);

		try {
			if (!event.target.files[0].type.match(/image.*/) || event.target.files[0].size > 2097152) {
				toast("File ảnh phải nhỏ hơn bằng 2MB", {
					type: "error",
					autoClose: 1000,
				});

				return;
			}

			setProductImages([
				...productImages,
				{
					id: imageId,
					src: event.target.files[0],
				},
			]);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Modal size="lg" show={showModal} onHide={() => handleCloseModal(false)} backdrop="static">
			<Modal.Header closeButton={!isSendForm}>
				<Modal.Title>Cập nhật sản phẩm</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div className="mb-3">
					<Form.Label>Chọn danh mục</Form.Label>

					<Select
						options={categoryOptions}
						placeholder={`Chọn chủ đề`}
						noOptionsMessage={() => "Không có lựa chọn"}
						getOptionLabel={(option) => option.cateNameVi}
						getOptionValue={(option) => option.id}
						value={categoryOptions.filter((category) => category.id === selectedCategory)}
						onChange={(choice) => {
							setSelectedCategory(choice.id);
						}}
						menuPortalTarget={document.body}
						styles={{
							menuPortal: (base) => ({ ...base, zIndex: 9999 }),
						}}
					/>
				</div>

				<Form
					id="edit-product-hook-form"
					onSubmit={handleSubmit((data) => {
						updateProduct(data);
					})}
					className="mb-5"
				>
					{editProductFormItems?.map((formItem) => {
						return (
							<div key={formItem.id} className="mb-3">
								<Form.Floating>
									<Form.Control
										type="text"
										placeholder={formItem.placeholder}
										name={formItem.name}
										{...register(formItem.name, {
											onChange: () => {
												setSlug(convertToSlug(getValues("name")));
											},
										})}
									/>

									<Form.Label>
										{formItem.placeholder}
										<i className="text-danger">*</i>
									</Form.Label>
								</Form.Floating>

								{errors[formItem.name] && (
									<p
										style={{
											fontSize: 13,
											color: "red",
											marginTop: 6,
										}}
									>
										{errors[formItem.name].message}
									</p>
								)}
							</div>
						);
					})}

					<Row className="mb-3">
						<Col xs="12" md="12" className="mb-3 mb-md-0">
							<Form.Floating>
								<Form.Control type="text" placeholder="Slug" name="slug" disabled value={slug} />

								<Form.Label>
									Slug
									<i className="text-danger">*</i>
								</Form.Label>
							</Form.Floating>
							{errMessage.proSlug && (
								<p
									style={{
										fontSize: 13,
										color: "red",
										marginTop: 6,
									}}
								>
									{errMessage.proSlug}
								</p>
							)}
						</Col>
					</Row>

					<div className="mb-3">
						<Form.Control placeholder={`Mô tả`} name={`description`} {...register(`description`)} as={`textarea`} rows={5} />

						{errors[`description`] && (
							<p
								style={{
									fontSize: 13,
									color: "red",
									marginTop: 6,
								}}
							>
								{errors[`description`].message}
							</p>
						)}
					</div>

					{/* Content */}
					<div className="mb-3">
						<Form.Label>Công thức</Form.Label>
						<TextEditor
							setContents={formula}
							onChange={(value) => setFormula(value)}
							placeholder={"Công thức"}
							style={{ border: "1px solid #dadada", padding: 16 }}
						/>
					</div>

					<div className="mb-3">
						<Form.Label>Công dụng</Form.Label>
						<TextEditor
							setContents={use}
							onChange={(value) => setUse(value)}
							placeholder={"Công dụng"}
							style={{ border: "1px solid #dadada", padding: 16 }}
						/>
					</div>

					<div className="mb-3">
						<Form.Label>Đặc điểm và tính năng</Form.Label>
						<TextEditor
							setContents={content}
							onChange={(value) => setContent(value)}
							placeholder={"Đặc điểm và tính năng"}
							style={{ border: "1px solid #dadada", padding: 16 }}
						/>
					</div>

					<div className="mb-3">
						<Form.Label>Hướng dẫn sử dụng</Form.Label>
						<TextEditor
							setContents={guide}
							onChange={(value) => setGuide(value)}
							placeholder={"Hướng dẫn sử dụng"}
							style={{ border: "1px solid #dadada", padding: 16 }}
						/>
					</div>
				</Form>

				{/* Image */}
				<div>
					<div className="d-flex justify-content-evenly flex-wrap">
						{productImages.length !== 0
							? productImages?.map((imageItem) => {
									return (
										<div
											key={imageItem.id}
											style={{
												height: 450,
												width: 300,
												backgroundImage: `url("${URL.createObjectURL(imageItem.src)}")`,
												backgroundSize: "cover",
											}}
											className="image-default position-relative mb-3"
										>
											<button
												style={{
													height: "1.5rem",
													width: "1.5rem",
													border: "none",
												}}
												className="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-danger"
												onClick={() => {
													setProductImages(productImages.filter((item) => item.id !== imageItem.id));
												}}
											>
												<Icon icon="x" />
											</button>
										</div>
									);
							  })
							: ""}
					</div>

					<input type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(e) => handleUploadImage(e)} required />
					<div>
						<div className="d-inline fw-bold">Note: </div>
						Chọn ảnh với tỉ lệ 900:600 để được chất lượng tốt nhất.
					</div>
				</div>
			</Modal.Body>

			<Modal.Footer>
				<Button type="button" variant="secondary" onClick={() => handleCloseModal(false)} disabled={isSendForm}>
					Đóng
				</Button>
				<Button type="submit" form="edit-product-hook-form" variant="primary" disabled={isSendForm || isLoading}>
					Cập nhật
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

const Schema = yup.object().shape({
	name: yup
		.string()
		.required("Không được bỏ trống")
		.test("len", "Tiêu đề không dài quá 255 kí tự", (val) => val.length >= 0 && val.length <= 255),
	description: yup
		.string()
		.required("Không được bỏ trống")
		.test("len", "Mô tả không dài quá 255 kí tự", (val) => val.length <= 255),
});

export default ProductEditModal;
