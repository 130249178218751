// Bao cao lan 5
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
// import { PRODUCT_DETAIL, SOLUTION, TOPIC } from "../../routes/routerUrl";
import ReactPlayer from "react-player";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Style.css";

import ImageURL from "../../components/ImageURL";
import NoData from "../../components/NoData";
import Loading from "../../components/Loading";
import Icon from "../../components/Icon";
// import News from "./News";
import ContactWithUs from "./ContactWithUs";

// image assets
import TopBanner from "../../assets/image/top-banner.png";
import ImgField1 from "../../assets/image/img-field-1.png";
import ImgField2 from "../../assets/image/img-field-2.png";
import ImgField3 from "../../assets/image/img-field-3.png";
import ImgField4 from "../../assets/image/img-field-4.png";
import Ctv from "../../assets/image/ctv.png";
import haivan from "../../assets/image/haivan.png";
import gena from "../../assets/image/gena.png";
import ImgWeb from "../../assets/image/new-banner.jpg";
import ImgTamNhin from "../../assets/image/tuong-lai.png";
import ImgGiaTri from "../../assets/image/gia-tri.svg";
import ImgSuMenh from "../../assets/image/su-menh.png";
import ImgPartner1 from "../../assets/partner/vbi-can-tho.png";
import ImgPartner2 from "../../assets/partner/viettel-can-tho.png";
import ImgPartner3 from "../../assets/partner/logo-tmt.png";
import ImgPartner4 from "../../assets/partner/viettin.jpg";
import ImgPartner5 from "../../assets/partner/posapp-fnb-logo.png";
import ImgPartner6 from "../../assets/partner/hdbank.png";
import Banner from "../../assets/image/BANNER_CNKN.png";
import Sangtao from "../../assets/image/BG_SANGTAO.png";
// import IconClock from "../../assets/icon/clock-solid.svg"
// import IconLocation from "../../assets/icon/location-dot-solid.svg"
// import IconUser from "../../assets/icon/user-solid.svg"
// import IconArrow from "../../assets/icon/arrow-right-solid.svg"

import moment from "moment";
import "moment/locale/vi";
import ShowProdcut from "./Showproduct";
moment.locale("vi");

const fieldActivities = [
  {
    id: 2,
    title:
      "­ Quản lý suất ăn và định lượng dinh dưỡng cho trẻ mầm non, tiểu học; Suất ăn khu công nghiệp, viện dưỡng lão, trại cai nghiện",
    imgUrl: ImgField2,
    url: "https://kafood.vn/",
  },
  {
    id: 3,
    title: "Quản lý Quán Café, ăn uống, shop, nhà sách, tạp hóa, nhà phân phối",
    imgUrl: ImgField3,
    url: "https://kaviet.vn",
  },
  {
    id: 1,
    title:
      "Thiết kế, xây dựng website chuyên lĩnh vực bán hàng, giới thiệu sản phẩm với độ an toàn cao,chuẩn SEO",
    imgUrl: ImgField1,
    url: "https://thietkeweb.katec.vn/",
  },
  {
    id: 5,
    title: "Quản lý dịch vụ quảng cáo của Đài phát thanh và Truyền hình",
    imgUrl: Ctv,
    url: "gioi-thieu-ctv",
    // checkModal: true,
  },

  {
    id: 6,
    title: "Quản lý kinh doanh dịch vụ biển hiệu quảng cáo ngoài trời ",
    imgUrl: haivan,
    url: "gioi-thieu-haivan",
    // checkModal: true,
  },
  {
    id: 4,
    title: "Quản lý kinh doanh dịch vụ Đại lý Bảo hiểm nhân thọ",
    imgUrl: gena,
    url: "gioi-thieu-gena",
  },
];

const hireActivities = [
  {
    id: 1,
    title: "Nhân viên lập trình ",
    url: "https://vieclamcantho.com.vn/katec?ts=1&keyword=katec",
    timeOpen: new Date(2024, 5, 17),
    timeClose: new Date(2024, 6, 30),
    address: "Cần Thơ",
    amount: "3",
    job: "PHÒNG LẬP TRÌNH",
  },

  {
    id: 3,
    title: "Nhân viên kinh doanh ",
    url: "https://vieclamcantho.com.vn/katec?ts=1&keyword=katec",
    timeOpen: new Date(2024, 5, 14),
    timeClose: new Date(2024, 7, 31),
    address: "Cần Thơ",
    amount: "1",
    job: "PHÒNG KINH DOANH",
  },
];

const Section = ({ title, description, children }) => {
  return (
    <div style={{ margin: "2rem 0rem 1rem 0rem" }}>
      <div className="mb-2">
        <div className="title-line" />
        <div className="d-flex justify-content-center">
          <div
            className="title-text px-2 px-md-5"
            style={{
              width: "fit-content",
              backgroundColor: "white",
              marginTop: "-1.4rem",
              textTransform: "uppercase",
            }}
          >
            {title}
          </div>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{
            fontSize: "18px",
            marginTop: "10px",
            textAlign: "center",
          }}
        >
          {description}
        </div>
      </div>
      {children}
    </div>
  );
};

const FieldActivityItem = ({ item }) => {
  const [showModal, setShowModal] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const onHideModal = () => {
    setShowModal(false);
  };
  const onShow = (item) => {
    setShowModal(true);
    setDataModal(item);
  };
  //
  const { imgUrl, title, url } = item;

  return item.checkModal ? (
    <div className="field-activity-title" onClick={() => onShow(item)}>
      <img
        src={imgUrl}
        alt=""
        class="field-activity-item"
        style={{ height: "auto", width: "100%" }}
      ></img>

      <div
        style={{ color: "blue", background: "#f2f2f2", textAlign: "justify" }}
      >
        {title}
      </div>
      {showModal && (
        <ShowProdcut
          show={showModal}
          onHide={() => onHideModal()}
          data={dataModal}
        />
      )}
    </div>
  ) : (
    <div>
      <a href={url} className="field-activity-title" target="_blank">
        <img
          src={imgUrl}
          alt=""
          class="field-activity-item"
          style={{ height: "auto", width: "100%" }}
        ></img>

        <div
          style={{ color: "blue", background: "#f2f2f2", textAlign: "justify" }}
        >
          {title}
        </div>
      </a>
    </div>
  );
};

const HireActivityItem = ({ item }) => {
  const { url, title, timeOpen, timeClose, address, amount, job } = item;
  return (
    <div className="hire-activity-item">
      <div className="hire-activity-title">
        <a className="hire-title" href={url} target="_blank">
          {title}
        </a>
        <span className="label-enum">Đang tuyển</span>
      </div>
      <div className="hire-activity-content01">
        {/* <div className="flex-center">
          <span>
            <Icon icon="clock" class="icons-clock" />
          </span>
          <span>{moment(timeOpen).format("DD.MM.YYYY")}</span> &nbsp;-&nbsp;{" "}
          <span>{moment(timeClose).format("DD.MM.YYYY")}</span>
        </div> */}
        {/* <div className="flex-center">
          <span>
            <Icon icon="location-dot" class="icons-location" />
          </span>
          <span>{address}</span>
        </div> */}
        {/* <div className="flex-center-end">
          <span>
            <Icon icon="user" class="icons-user" />
          </span>
          <span>{amount}</span>
        </div> */}
      </div>
      <div className="hire-activity-content02">
        <span className="job-title">{job}</span>
        <a href={url}>
          <div className="hire-detail">
            <a className="job-detail" href={url} target="_blank">
              Chi tiết
            </a>
            <span>
              <Icon icon="arrow-right" class="icons-arrow" />
            </span>
          </div>
        </a>
      </div>
    </div>
  );
};

const Home = () => {
  const [banners, setBanners] = useState([]);
  const [isBannerLoading, setBannerIsLoading] = useState(false);

  const [medias, setMedias] = useState([]);
  const [showMedia, setShowMedia] = useState({});
  const [isMediaLoading, setIsMediaLoading] = useState(false);

  const [solutions, setSolutions] = useState([]);
  const [posts, setPosts] = useState([]);
  const [isPostLoading, setIsPostLoading] = useState(false);

  const [products, setProducts] = useState([]);
  const [isProductLoading, setIsProductLoading] = useState(false);

  const [showContactWithUs, setShowContactWithUs] = useState(false);

  const handleCloseContactWithUs = () => setShowContactWithUs(false);
  const handleShowContactWithUs = () => setShowContactWithUs(true);

  useEffect(() => {
    document.title = "Trang chủ";
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // API

  return (
    <>
      <ContactWithUs
        show={showContactWithUs}
        handleClose={handleCloseContactWithUs}
      />

      <div className="top-banner">
        <div className="br-img" style={{ backgroundImage: `url(${Banner})` }} />
        {/* <div className="slide-content">
       
          <img
            src={Banner}
            alt=""
            class="custom-image"
            style={{ height: "auto", width: "100%" }}
          ></img>

        </div> */}
      </div>

      {/* Lĩnh vực hoạt động */}
      <Container className="container-wrapper justify-content-center">
        <Section
          title="Lĩnh vực hoạt động"
          description="
	"
        >
          <p className="fw-8" style={{ textAlign: "justify" }}>
            <strong className="text-primary"> KATEC </strong>là công ty chuyên
            về lĩnh vực Công nghệ thông tin với đội ngũ lập trình là các kỹ sư
            CNTT có kinh nghiệm, đã qua thực chiến làm ra các phần mềm cung cấp
            trên thị trường.
            <br></br>
            Chúng tôi tự hào{" "}
            <span className="text-primary fw_8">
              là doanh nghiệp khoa học công nghệ tại Thành phố Cần Thơ
            </span>{" "}
            chuyên nghiệp trong ngành Công nghệ thông tin hiện nay.
            <br></br>
            <strong className="text-primary">
              Các phần mềm cung cấp trên thị trường:
            </strong>
          </p>

          <Row>
            {fieldActivities.map((item) => {
              return (
                <Col xs="12" md="4" className={"py-3"}>
                  <FieldActivityItem item={item} />
                </Col>
              );
            })}
          </Row>
        </Section>
      </Container>

      {/* Giới thiệu */}

      {/* Sứ mệnh */}
      <div
        className="home-item-th01 br-img01"
        style={{ backgroundImage: `url(${ImgSuMenh})` }}
      >
        <div className="row-mission">
          <div className="col-md-12 text-center">
            <h2 class="fs_36 fw_8 title">SỨ MỆNH QUAN TRỌNG CỦA CHÚNG TÔI</h2>
          </div>

          <div className="col-md-4 flex-stretch col-12">
            <div className="item">
              <p class="fs_30 fw_6">
                Đưa công nghệ <br></br>vào tay mọi người
              </p>
            </div>
          </div>
          <div className="col-md-4 flex-stretch col-12">
            <div className="item">
              <p class="fs_30 fw_6">
                Thay đổi cuộc sống và <br></br>đổi mới liên tục
              </p>
            </div>
          </div>
          <div className="col-md-4 flex-stretch col-12">
            <div className="item">
              <p class="fs_30 fw_6">
                Tạo ra môi trường làm việc <br></br> tích cực để phát triển tài
                năng.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Tầm nhìn */}
      <Row className="align-self-center">
        <Col md={6} className="px-md-0">
          <div
            className="home-item-th02 br-img02"
            style={{ background: "#3f48cc" }}
          >
            <div className="row-vision">
              <div class=" pd-content">
                <div class="title-vision">Chủ trương phát triển với</div>
                <div class="sub-title">TẦM NHÌN</div>
                <div
                  class="item-vision"
                  style={{ borderBottom: "1px solid #f0f0f0" }}
                >
                  <a class="nav-link">SÁNG TẠO để tạo ra giá trị tốt nhất!</a>
                </div>
                <div
                  class="item-vision"
                  style={{ borderBottom: "1px solid #f0f0f0" }}
                >
                  <a class="nav-link">BỀN VỮNG để tạo ra giá trị cao nhất!</a>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={6} className="ps-md-0">
          <div
            className="delta-image-backgroundvalue"
            style={{ backgroundImage: `url(${Sangtao})` }}
          >
            <div class=" pd-content fw-medium">
              {/* <div class="title-vision">GIÁ TRỊ CỐT LÕI</div> */}
              <div class="sub-title" style={{ color: "#01f0f9" }}>
                GIÁ TRỊ CỐT LÕI
              </div>
              <Row>
                <Col>
                  <div
                    class="item-vision2"
                    style={{ borderBottom: "1px solid #f0f0f0" }}
                  >
                    <a class="nav-link"> ĐỔI MỚI</a>
                  </div>
                </Col>
                <Col>
                  <div
                    class="item-vision2"
                    style={{ borderBottom: "1px solid #f0f0f0" }}
                  >
                    <a class="nav-link">SÁNG TẠO</a>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div
                    class="item-vision2"
                    style={{ borderBottom: "1px solid #f0f0f0" }}
                  >
                    <a class="nav-link"> TẬN TÂM</a>
                  </div>
                </Col>
                <Col>
                  <div
                    class="item-vision2"
                    style={{ borderBottom: "1px solid #f0f0f0" }}
                  >
                    <a class="nav-link">BỀN VỮNG</a>
                  </div>
                </Col>
              </Row>
            </div>
            {/* <div className="text-cotloi">GIÁ TRỊ CỐT LÕI</div> */}
            {/* <div className="fill delta-image-background"/> */}
          </div>
          {/* <img src={Sangtao} alt="" className="imageST"></img> */}
        </Col>
      </Row>

      {/* Giá trị */}
      <div
        className="home-item-th03 br-img03"
        style={{ backgroundImage: `url(${ImgGiaTri})` }}
      >
        <div className="row-value">
          <div class="col-md-12">
            <h2 class="fs_46 fw_4 title" style={{ color: "darkblue" }}>
              KATEC luôn luôn hướng tới
            </h2>
            <h2 class="fs_46 fw_4 title">GIÁ TRỊ CỐT LÕI</h2>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="item-value">
              <p class="fs_32">ĐỔI MỚI</p>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="item-value">
              <p class="fs_32">SÁNG TẠO</p>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="item-value">
              <p class="fs_32">TẬN TÂM</p>
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <div class="item-value">
              <p class="fs_32">BỀN VỮNG</p>
            </div>
          </div>
        </div>
      </div>

      {/* Tuyển dụng */}
      <Container className="container-wrapper justify-content-center">
        <Section title="Tin tuyển dụng">
          <Row style={{ marginTop: "-20px" }}>
            {hireActivities.map((item) => {
              return (
                <Col xs="11" md="6">
                  <HireActivityItem item={item} />
                </Col>
              );
            })}
          </Row>
        </Section>
      </Container>

      {/* Đối tác */}
      <div className="home-item-th04">
        <div className="row-partner">
          <div class="col-md-12 text-center">
            <Container>
              <Section title="Đối tác"></Section>
            </Container>
          </div>
          <div className="col-md-12 w-101" style={{ marginTop: "-20px" }}>
            <div class="w-partner">
              <img src={ImgPartner4} alt="" class="w-partner04"></img>
            </div>
            <div class="w-partner">
              <img src={ImgPartner2} alt="" class="w-partner02"></img>
            </div>
            <div class="w-partner">
              <img src={ImgPartner3} alt="" class="w-partner03"></img>
            </div>
            <div class="w-partner">
              <img src={ImgPartner5} alt="" class="w-partner01"></img>
            </div>
            <div class="w-partner">
              <img src={ImgPartner1} alt="" class="w-partner01"></img>
            </div>
            <div class="w-partner">
              <img src={ImgPartner6} alt="" class="w-partner03"></img>
            </div>
          </div>
        </div>
      </div>

      {/* Hợp tác */}
      <div
        className="home-item-th05"
        style={{ backgroundImage: `url(${TopBanner})` }}
      >
        <div className="delta-fill">
          <div className="">
            <Row className="align-self-center">
              <Col xs="11" md="6" className={" align-self-center"}>
                <div
                  className="delta-image-background"
                  style={{ backgroundImage: `url(${ImgWeb})` }}
                >
                  {/* <div className="fill delta-image-background"/> */}
                </div>
              </Col>
              <Col xs="11" md="6" className={" align-self-center"}>
                <h1 className="white-text" style={{ color: "cyan" }}>
                  HỢP TÁC CÙNG KATEC
                </h1>
                <p className="white-text">
                  Chúng tôi liên kết với rất nhiều các đối tác trên khắp cả
                  nước, hợp tác hiệu quả với các công ty CNTT chuyên môn cao để
                  đạt được chất lượng công việc cao nhất!
                </p>
                <p className="white-text">
                  KATEC mong muốn xây dựng những mối quan hệ hợp tác chiến lược
                  để có thể cùng nhau tiến xa hơn, tiến mạnh mẽ hơn. Hãy tham
                  gia cùng với chúng tôi!
                </p>
                <div className="d-flex" style={{ marginLeft: "15%" }}>
                  <div
                    className="button white is-outline lowercase"
                    style={{ borderRadius: "99px" }}
                    onClick={handleShowContactWithUs}
                  >
                    <span>Hợp tác</span>
                    <i class="icon-angle-right" aria-hidden="true" />
                  </div>
                  <Link
                    to="lien-he"
                    className="button white lowercase"
                    style={{ borderRadius: "99px" }}
                  >
                    <span>Liên hệ</span>
                    <i class="icon-angle-right" aria-hidden="true" />
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      {/* Những công trình nổi bật */}
      {/* <Container className="container-wrapper justify-content-center">
				<Section
					title="Các sản phẩm nổi bật"
					description="Công ty KATEC chuyên cung cấp các sản phẩm CNTT như là website, phần mềm quản lý kinh doanh cho doanh nghiệp, quán cafe, shop, spa,..."
				>
					<Row>
						{outstandingProjects.map((item, index) => {
							const mod = index % 4;
							return (
								<Col
									xs="11"
									md={(mod === 0 || mod === 3) ? "8" : "4"}
									className={"py-3"}
								>
									<OutstandingProjectItem item={item} />
								</Col>
							);
						})}
					</Row>
				</Section>
			</Container> */}

      {/* Tin tức */}
      {/* <Container className="container-wrapper justify-content-center">
				<Section
					title="Tin Tức và Sự Kiện"
					description="Cập nhật những tin tức mới nhất và những sự kiện nổi bật"
				>
					<Row
						className={"px-2"}
					>
						{news.map((item, index) => {
							const { title, time, imgUrl, expcert, url } = item;
							const mod = (index % 6);
							const isLarge = (mod === 1) || (mod === 5);
							return (
								<Col
									xs="11"
									md={isLarge ? "6" : "3"}
									className={"py-2"}
								>
									<News
										imageUrl={imgUrl}
										title={title}
										url={url}
										time={time}
										expcert={expcert}
									/>
								</Col>
							);
						})}
					</Row>
				</Section>
			</Container> */}
    </>
  );
};

export default Home;
