import { useEffect, useState } from "react";

import { Button, Modal, NavItem } from "react-bootstrap";

const ShowProdcut = ({ show, onHide, data }) => {
  useEffect(() => {}, []);
  const [showModal, setShow] = useState(show);
  const onHideModal = () => {
    setShow(false);
  };
  //API

  return (
    <>
      <Modal show={showModal} onHide={onHideModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{data.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={data.imgUrl}
            alt=""
            class="field-activity-item"
            style={{ height: "auto", width: "100%" }}
          ></img>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShowProdcut;
