import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components
import UserLayout from "../layouts/user/MasterLayout";
import Login from "../pages/user/Auth/Login";
import Home from "../pages/user/Home";
import Introduction from "../pages/user/Introduction";
import Product from "../pages/user/Product/Product";
import ProductDetail from "../pages/user/Product/ProductDetail";
import Contact from "../pages/user/Contact";
import New from "../pages/user/New/New";
import NewDetail from "../pages/user/New/NewDetail";
import Topic from "../pages/user/New/Topic";
// import SolutionType from "../pages/user/Solution/SolutionType";
// import Solution from "../pages/user/Solution/Solution";
// import SolutionDetail from "../pages/user/Solution/SolutionDetail";
import MediaDetail from "../pages/user/Media/MediaDetail";
import Media from "../pages/user/Media/Media";

import AdminLayout from "../layouts/admin/MasterLayout";
import Dashboard from "../pages/admin/Dashboard";
import Setting from "../pages/admin/Setting";
import IntroductionAdmin from "../pages/admin/Introduction";
import ProductAdmin from "../pages/admin/Product/Product";
import CategoryAdmin from "../pages/admin/Category/Category";
import TopicAdmin from "../pages/admin/Topic/Topic";
import NewAdmin from "../pages/admin/New/New";
// import SolutionTypeAdmin from "../pages/admin/SolutionType/SolutionType";
// import SolutionAdmin from "../pages/admin/Solution/Solution";
import MediaAdmin from "../pages/admin/Media/Media";
import BannerAdmin from "../pages/admin/Banner/Banner";
import MemberAdmin from "../pages/admin/Member/Member";

// URLs
import {
	CONTACT_URL,
	INTRODUCTION_URL,
	MEDIA_DETAIL_URL,
	MEDIA_URL,
	NEW_DETAIL_URL,
	NEW_URL,
	PRODUCT_DETAIL_URL,
	PRODUCT_URL,
	// SOLUTION_DETAIL_URL,
	// SOLUTION_TYPE_URL,
	// SOLUTION_URL,
	TOPIC_URL,
} from "./routerUrl";
import NotFound from "../pages/NotFound";
import CTV from "../pages/user/detail/CTV";
import HaiVan from "../pages/user/detail/HaiVan";
import Gena from "../pages/user/detail/Gena";

export default function router() {
	return (
		<>
			<BrowserRouter>
				<ToastContainer position="top-right" />

				<ScrollToTop />
				<Routes>
					<Route path="/" element={<UserLayout />}>
						<Route index element={<Home />} />

						<Route path={"dang-nhap"} element={<Login />} />
						<Route path={INTRODUCTION_URL} element={<Introduction />} />
						<Route path={PRODUCT_URL} element={<Product />} />
						<Route path={PRODUCT_DETAIL_URL} element={<ProductDetail />} />
						<Route path={TOPIC_URL} element={<Topic />} />
						<Route path={NEW_URL} element={<New />} />
						<Route path={NEW_DETAIL_URL} element={<NewDetail />} />

						<Route path={MEDIA_URL} element={<Media />} />
						<Route
							path={MEDIA_DETAIL_URL}
							element={<MediaDetail />}
						/>

						<Route path={CONTACT_URL} element={<Contact />} />
						<Route path={"gioi-thieu-ctv"} element={<CTV />} />
						<Route path={"gioi-thieu-haivan"} element={<HaiVan />} />
						<Route path={"gioi-thieu-gena"} element={<Gena />} />

						<Route path="*" element={<NotFound path={"/"} />} />
					</Route>

					<Route path="/admin" element={<AdminLayout />}>
						<Route index element={<Dashboard />} />
						<Route path={`intro`} element={<IntroductionAdmin />} />

						<Route path={`category`} element={<CategoryAdmin />} />
						<Route path={`product`} element={<ProductAdmin />} />
						<Route path={`topic`} element={<TopicAdmin />} />
						<Route path={`new`} element={<NewAdmin />} />

						{/*<Route
							path={`typeSolution`}
							element={<SolutionTypeAdmin />}
						/>
						<Route path={`solution`} element={<SolutionAdmin />} />*/}
						<Route path={`media`} element={<MediaAdmin />} />
						<Route path={`banner`} element={<BannerAdmin />} />
						<Route path={`member`} element={<MemberAdmin />} />
						<Route path={`setting`} element={<Setting />} />

						<Route path="*" element={<NotFound path={"/admin"} />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</>
	);
}
