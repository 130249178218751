import {
	Button,
	Card,
	Col,
	Container,
	Form,
	InputGroup,
	Row,
} from "react-bootstrap";
import Input from "../../../components/Input";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../../utils/yupGlobal";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Icon from "../../../components/Icon";

const Login = () => {
	const navigate = useNavigate();
	const [passwordShow, setPasswordShow] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(Schema),
	});

	useEffect(() => {
		document.title = "Đăng nhập";

		if (localStorage.getItem("access_token")) {
			navigate("/admin");
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const login = (data) => {
		axios
			.post(`/login`, {
				userName: data.username,
				password: data.password,
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					localStorage.setItem(
						"access_token",
						res.data.data.accessToken,
					);

					toast(res.data.message, {
						type: "success",
						autoClose: 2000,
					});

					window.location.href = "/admin";
				} else {
					toast("Tên đăng nhập hoặc mật khẩu không đúng", {
						type: "error",
						autoClose: 2000,
					});
				}
			});
	};

	return (
		<Container className="container-wrapper">
			<div className="title text-center my-3">Đăng nhập</div>

			<Row className="justify-content-center">
				<Col xs="12" sm="8" md="6">
					<Card className="p-0 p-xl-5 mb-5 border-0">
						<Form
							onSubmit={handleSubmit((data) => {
								login(data);
							})}
						>
							<Input
								placeholder={"Tên đăng nhập"}
								name="username"
								register={register("username")}
								errors={errors.username}
								className="mb-3"
							/>
							<div className="mb-3">
								<InputGroup>
									<Form.Control
										placeholder={"Mật khẩu"}
										type={
											passwordShow ? "text" : "password"
										}
										name="password"
										{...register("password")}
									/>
									<Button
										onClick={() => {
											setPasswordShow(!passwordShow);
										}}
									>
										<Icon
											icon={
												passwordShow
													? "eye-slash"
													: "eye"
											}
										/>
									</Button>
								</InputGroup>
								{errors.password && (
									<p
										style={{
											fontSize: 13,
											color: "red",
											marginTop: 6,
										}}
									>
										{errors.password.message}
									</p>
								)}
							</div>

							<Button
								type="submit"
								size="lg"
								className="mlb-button w-100"
							>
								Đăng nhập
							</Button>
						</Form>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

const Schema = yup.object().shape({
	password: yup.string().required("Không được bỏ trống"),
	username: yup.string().required("Không được bỏ trống"),
});

export default Login;
