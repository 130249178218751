import React from "react";
import {
  Col,
  Container,
  Row,
  Stack,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Icon from "../../components/Icon";
import ZaloIcon from "../../assets/image/zalo-icon.png";
import PhoneIcon from "../../assets/image/icon-phone.png";
import { Link } from "react-router-dom";
import { CONTACT_URL, CAREER, PROJECT } from "../../routes/routerUrl";

import {
  faFacebookF,
  faTwitter,
  faLinkedin,
  faPinterest,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faFileAlt, faHome, faPhone, faVoicemail } from "@fortawesome/free-solid-svg-icons";

const socialNetwork = [
  {
    id: 1,
    title: "facebook",
    lable: "Chia sẻ trên Facebook",
    link: "https://www.facebook.com/kateccantho",
    icon: faFacebookF,
  },
  {
    id: 2,
    title: "twitter",
    lable: "Chia sẻ trên Twitter",

    icon: faTwitter,
  },
  {
    id: 3,
    title: "email",
    lable: "Email cho bạn bè",

    icon: "envelope",
  },
  {
    id: 4,
    title: "pinterest",
    lable: "Ghim trên Pinterest",

    icon: faPinterest,
  },
  {
    id: 5,
    title: "linkedin",
    lable: "Chia sẻ trên LinkedIn",
    icon: faLinkedin,
  },
];

const links = [
  {
    id: 1,
    title: "Kafood",
    link: "https://kafood.vn/",
  },
  {
    id: 2,
    title: "Thông tin tuyển dụng",
    link: CAREER,
  },
  {
    id: 3,
    title: "Chính sách bảo mật",
    link: PROJECT,
  },
  {
    id: 4,
    title: "Liên hệ",
    link: CONTACT_URL,
  },
];
const Footer = ({ shop }) => {
  return (
    <footer className="text-start bg-light">
      {/* Information */}
      <section className="py-4">
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" md="4" className={"py-3"}>
              <div className="fw-bold d-inline">
                CÔNG TY CỔ PHẦN CÔNG NGHỆ KATEC
              </div>
              <p>
                <Icon icon={faHome} /> Số 189, đường Phan Huy Chú, phường An Khánh, quận Ninh Kiều, thành phố Cần Thơ
                <br />
                <Icon icon={faPhone} /> <Link to="tel: 0889881010" className="text-decoration-none text-dark">0889 88 1010</Link>
                <br />
                <Icon icon={faFileAlt} /> MST: 1801633969
                <br />
                <Icon icon={faEnvelope} /> <Link to="mailto:katec.cantho@gmail.com" className="text-decoration-none text-dark">katec.cantho@gmail.com</Link>
              </p>

              <div className="d-flex">
                {socialNetwork.map((item) => {
                  return (
                    <OverlayTrigger
                      overlay={<Tooltip id={item.id}>{item.lable}</Tooltip>}
                    >
                      <a
                        href={item.link}
                        onclick="window.open(this.href,this.title,'width=500,height=500,top=300px,left=300px'); return false;"
                        target="_blank"
                        rel="noopener nofollow"
                        className={`icon-circle-outline ${item.title}`}
                      >
                        <Icon icon={item.icon} />
                      </a>
                    </OverlayTrigger>
                  );
                })}
              </div>
            </Col>

            <Col xs="12" md="4" className={"py-3"}>
              <div className="fw-bold d-inline">HƯỚNG DẪN KHÁCH HÀNG</div>
              <div class="textwidget">
                <ul>
                  <li><Link to="#" className="text-decoration-none text-dark">Chính sách bảo mật thông tin</Link></li>
                  <li><Link to="#" className="text-decoration-none text-dark">Điều khoản sử dụng</Link></li>
                </ul>
              </div>
            </Col>
            <Col xs="12" md="4" className={"py-3"}>
              <iframe
                title="safe life"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3928.706498664805!2d105.75135877421363!3d10.041060572328492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31a0886c3ecc3e01%3A0x5a84e770728f1669!2zMTg5IMSQLiBQaGFuIEh1eSBDaMO6LCBQaMaw4budbmcgQW4gS2jDoW5oLCBOaW5oIEtp4buBdSwgQ-G6p24gVGjGoSwgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1683166794218!5m2!1svi!2s"
                width="100%"
                height="160"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section
        className="py-3"
        style={{
          borderTop: "1px solid #ebebeb",
          backgroundColor: "#2d3e4b",
          color: "hsla(0, 0%, 100%, .5)",
        }}
      >
        <Container>
          <Row className="j">

            <Col md="4" xs="12" className="text-center text-md-start  align-content-center">
              <img
                data-lazyloaded="1"
                src={PhoneIcon}
                data-src={PhoneIcon}
                data-ll-status="loaded"
                style={{ width: "40px" }}
              />
              <span className="text-white">Hotline: <Link to="tel: 0889881010" className="text-decoration-none text-white">0889 88 1010</Link></span>
            </Col>

            <Col md="4" xs="12" className="text-center align-content-center mb-2 mb-md-0">
              <span className="text-white">Copyright © 2019 by Katec</span>
            </Col>

            <Col md="4" xs="12" className="text-center text-md-end align-content-center" >
              <span className="text-white"> Kết nối với Katec: </span>

              <OverlayTrigger
                overlay={
                  <Tooltip id="facebook">Theo dõi trên Facebook</Tooltip>
                }
              >
                <a
                  href="https://www.facebook.com/kateccantho"
                  target="_blank"
                  rel="noopener nofollow"
                  className={"icon-circle facebook"}
                >
                  <Icon
                    icon={faFacebookF}
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </a>
              </OverlayTrigger>
              <OverlayTrigger
                overlay={
                  <Tooltip id={"youtube"}>Theo dõi trên YouTube</Tooltip>
                }
              >
                <a
                  href="https://www.youtube.com/@congtykatec"
                  target="_blank"
                  rel="noopener nofollow"
                  className={"icon-circle youtube"}
                >
                  <Icon
                    icon={faYoutube}
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </a>
              </OverlayTrigger>
            </Col>
          </Row>
        </Container>
      </section>
    </footer>
  );
};

export default Footer;
