import { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../../utils/yupGlobal";
import { toast } from "react-toastify";

import { Button, Form, Modal } from "react-bootstrap";
import convertToSlug from "../../../utils/slugFormatter";

const CategoryAddModal = ({ handleCloseModal, showModal, parentCate }) => {
	const [slug, setSlug] = useState("");

	const [isSendForm, setIsSendForm] = useState(false);
	const [errMessage, setErrMessage] = useState({
		cateSlug: undefined,
	});

	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(Schema),
	});

	const createNewCategory = async (data) => {
		setIsSendForm(true);

		var bodyFormData = new FormData();
		bodyFormData.append("cateNameVi", data.name);
		bodyFormData.append("cateSlug", slug);
		bodyFormData.append("isType", "cPro");
		bodyFormData.append("cateParentId", parentCate.id || process.env.REACT_APP_CATEGORY_ID);

		await axios({
			method: "post",
			url: "category/create",
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then((res) => {
				setIsSendForm(false);

				if (res.data.errCode === 0) {
					toast(res.data.message, {
						type: "success",
						autoClose: 1000,
					});
					handleCloseModal(true);
				} else {
					setErrMessage(res.data.message);
					toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<Modal show={showModal} onHide={() => handleCloseModal(false)} backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>Thêm mới danh mục</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form
					id="add-category-hook-form"
					onSubmit={handleSubmit((data) => {
						createNewCategory(data);
					})}
				>
					{parentCate.id ? (
						<Form.Floating className="mb-3">
							<Form.Control type="text" placeholder={`Danh mục cha`} value={parentCate.cateNameVi} disabled />

							<Form.Label>{`Danh mục cha`}</Form.Label>
						</Form.Floating>
					) : (
						""
					)}

					<div className="mb-3">
						<Form.Floating>
							<Form.Control
								type="text"
								placeholder={`Tên danh mục`}
								name={`name`}
								{...register(`name`, {
									onChange: () => {
										setSlug(convertToSlug(getValues("name")));
									},
								})}
							/>

							<Form.Label>
								{`Tên danh mục`}
								<i className="text-danger">*</i>
							</Form.Label>
						</Form.Floating>

						{errors[`name`] && (
							<p
								style={{
									fontSize: 13,
									color: "red",
									marginTop: 6,
								}}
							>
								{errors[`name`].message}
							</p>
						)}
					</div>

					<div className="mb-3">
						<Form.Floating>
							<Form.Control type="text" placeholder="Slug" name="slug" disabled value={slug} />

							<Form.Label>
								Slug
								<i className="text-danger">*</i>
							</Form.Label>
						</Form.Floating>

						{errMessage.cateSlug && (
							<p
								style={{
									fontSize: 13,
									color: "red",
									marginTop: 6,
								}}
							>
								{errMessage.cateSlug}
							</p>
						)}
					</div>
				</Form>
			</Modal.Body>

			<Modal.Footer>
				<Button type="button" variant="secondary" onClick={() => handleCloseModal(false)} disabled={isSendForm}>
					Đóng
				</Button>
				<Button type="submit" form="add-category-hook-form" variant="primary" disabled={isSendForm}>
					Lưu
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

const Schema = yup.object().shape({
	name: yup
		.string()
		.required("Không được bỏ trống")
		.test("len", "Tên chủ đề không dài quá 255 kí tự", (val) => val.length >= 0 && val.length <= 255),
});

export default CategoryAddModal;
