import moment from "moment";
import "moment/locale/vi";
moment.locale("vi");

const DateFormat = ({ date, className, style }) => {
	return (
		<div
			style={{ ...style, color: "#727272", fontSize: "0.75rem" }}
			className={`${className}`}
		>
			{moment(date * 1000).format("dddd DD/MM/YYYY")}
		</div>
	);
};

export default DateFormat;
