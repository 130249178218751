import React from "react";
import { Link, useLocation } from "react-router-dom";
import Icon from "../../components/Icon";

const navbarItems = [
	{
		id: 0,
		label: "Tổng quan",
		icon: "gauge",
		link: "",
	},
	{
		id: 1,
		label: "Giới thiệu",
		icon: "network-wired",
		link: "intro",
	},
	{
		id: 2,
		label: "Sản phẩm",
		icon: "shirt",
		link: "product",
	},
	/*{
		id: 3,
		label: "Giải pháp",
		icon: "seedling",
		link: "solution",
	},*/
	{
		id: 4,
		label: "Tin tức",
		icon: "newspaper",
		link: "new",
	},
	{
		id: 5,
		label: "Media",
		icon: "photo-film",
		link: "media",
	},
];

const settingItems = [
	{
		id: 0,
		label: "Cửa hàng",
		icon: "shop",
		link: "setting",
	},
	{
		id: 1,
		label: "Banner",
		icon: "image",
		link: "banner",
	},
	{
		id: 2,
		label: "Quản lý danh mục",
		icon: "tags",
		link: "category",
	},
	/*{
		id: 3,
		label: "Loại giải pháp",
		icon: "lightbulb",
		link: "typeSolution",
	},*/
	{
		id: 4,
		label: "Quản lý chủ đề",
		icon: "book-open-reader",
		link: "topic",
	},
];

const Sidebar = () => {
	const location = useLocation();

	return (
		<nav
			className="sb-sidenav accordion sb-sidenav-light"
			id="sidenavAccordion"
		>
			<div className="sb-sidenav-menu">
				<div className="nav">
					{navbarItems.map((item) => {
						return (
							<Link
								key={item.id}
								to={`/admin/${item.link}`}
								className={`nav-link nav-link-hover ${
									item.id === 0
										? location.pathname === "/admin" ||
										  location.pathname === "/admin/"
											? "nav-link-on-hover active"
											: ""
										: location.pathname.startsWith(
												`/admin/${item.link}`,
										  )
										? "nav-link-on-hover active"
										: ""
								}`}
							>
								<div className="sb-nav-link-icon me-2">
									<Icon icon={item.icon} />
								</div>
								{item.label}
							</Link>
						);
					})}

					<Link
						className="nav-link-hover nav-link collapsed"
						data-bs-toggle="collapse"
						data-bs-target={`#setting`}
						aria-expanded="false"
						aria-controls={`setting`}
					>
						<div className="sb-nav-link-icon">
							<Icon icon="gear" />
						</div>
						Cài đặt
						<div className="sb-sidenav-collapse-arrow">
							<Icon icon="chevron-down" />
						</div>
					</Link>
					<div
						id={`setting`}
						className="collapse"
						aria-labelledby="headingOne"
					>
						<nav className="sb-sidenav-menu-nested nav">
							{settingItems.map((item) => {
								return (
									<Link
										key={item.id}
										to={item.link}
										className={`nav-link nav-link-hover ${
											location.pathname.startsWith(
												`/admin/${item.link}`,
											)
												? "nav-link-on-hover active"
												: ""
										}`}
									>
										<div className="sb-nav-link-icon">
											<Icon icon={item.icon} />
										</div>
										{item.label}
									</Link>
								);
							})}
						</nav>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Sidebar;
