import { Col, Container, Row } from "react-bootstrap";

const MediaWrapper = ({ children }) => {
	return (
		<Container className="container-wrapper my-5">
			<Row className="justify-content-center">
				<Col xs="12" md="8">
					{children}
				</Col>
			</Row>
		</Container>
	);
};

export default MediaWrapper;
