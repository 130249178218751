import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";

const BannerAddModal = ({ handleCloseModal, showModal }) => {
	const [bannerImage, setBannerImage] = useState("");

	const [isSendForm, setIsSendForm] = useState(false);

	const createBanner = async () => {
		setIsSendForm(true);

		var bodyFormData = new FormData();
		bodyFormData.append("imageFile", bannerImage);
		bodyFormData.append("locaId", 12);

		await axios({
			method: "post",
			url: "image/create",
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then((res) => {
				setIsSendForm(false);

				if (res.data.errCode === 0) {
					toast(res.data.message, {
						type: "success",
						autoClose: 1000,
					});
					handleCloseModal(true);
				} else {
					toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	const handleUploadImage = (event) => {
		try {
			if (
				!event.target.files[0].type.match(/image.*/) ||
				event.target.files[0].size > 2097152
			) {
				toast("File ảnh phải nhỏ hơn bằng 2MB", {
					type: "error",
					autoClose: 1000,
				});

				return;
			}

			setBannerImage(event.target.files[0]);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Modal
			size="lg"
			show={showModal}
			onHide={() => handleCloseModal(false)}
			backdrop="static"
		>
			<Modal.Header closeButton={!isSendForm}>
				<Modal.Title>Thêm mới tin tức</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div>
					{bannerImage ? (
						<div className="d-flex justify-content-center">
							<div
								style={{
									aspectRatio: "1600/500",
									backgroundImage: `url("${URL.createObjectURL(
										bannerImage,
									)}")`,
									backgroundSize: "cover",
									width: "100%",
								}}
								className="image-default position-relative mb-3"
							/>
						</div>
					) : (
						""
					)}

					<input
						type="file"
						accept="image/x-png,image/gif,image/jpeg"
						onChange={(e) => handleUploadImage(e)}
						required
					/>
					<div>
						<div className="d-inline fw-bold">Note: </div>
						Chọn ảnh với tỉ lệ 1600:500 để được chất lượng tốt nhất.
					</div>
				</div>
			</Modal.Body>

			<Modal.Footer>
				<Button
					type="button"
					variant="secondary"
					onClick={() => handleCloseModal(false)}
					disabled={isSendForm}
				>
					Đóng
				</Button>
				<Button
					type="button"
					variant="primary"
					onClick={() => createBanner()}
					disabled={isSendForm}
				>
					Lưu
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default BannerAddModal;
