import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const ContactWithUs = ({ show, handleClose }) => {

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                centered
            >
                <Modal.Body>
                    <Form>
                        <h3
                            className="d-flex justify-content-center"
                            style={{
                                color: "var(--primary-color)",
                                fontSize: "26px",
                                padding: "15px",
                            }}
                        >
                            Liên hệ hợp tác với KATEC
                        </h3>
                        {/* <div className="d-flex justify-content-center gap-5"></div> */}
                            <Form.Control
                                id="ten_cong_ty"
                                type="text"
                                placeholder="Tên công ty"
                                className="mb-3 input"
                            />
                            <Form.Control
                                id="ma_so_thue"
                                type="text"
                                className="mb-3 input"
                                placeholder="Mã số thuế"
                            />
                        
                        {/* <div className="d-flex  justify-content-center gap-5"></div> */}
                            <Form.Control
                                id="nguoi_dai_dien"
                                type="text"
                                className="mb-3 input"
                                placeholder="Người đại diện"
                            />
                            <Form.Control
                                id="so_dien_thoai"
                                type="text"
                                className="mb-3 input"
                                placeholder="Số điện thoại"
                            />
                        
                        <Form.Control
                            id="dia_chi_cong_ty"
                            type="text"
                            className="mb-3 input"
                            placeholder="Địa chỉ công ty"
                        />
                        {/*<Form.Control
                            id="hang_muc_thi_cong"
                            type="text"
                            className="mb-3 input"
                            placeholder="Hạng mục thi công"
                        />
                        <Form.Control
                            id="khu_vuc_thi_cong"
                            type="text"
                            className="mb-3 input"
                            placeholder="Khu vực thi công"
                        />
                        <Form.Control
                            id="doanh_thu"
                            type="text"
                            className="mb-3 input"
                            placeholder="Doanh thu 2 năm gần nhất"
                        />
                        <Form.Control
                            id="cac_du_an"
                            type="text"
                            className="mb-3 input"
                            placeholder="Các dự án đã thi công"
                        />
                        <Form.Control
                            id="tong_thau"
                            type="text"
                            className="mb-3 input"
                            placeholder="Tổng thầu- CDT từng hợp tác"
                        />*/}
                    </Form>
                    <div className="d-flex  justify-content-center">
                        <Form.Control
                            type="submit"
                            onClick={handleClose}
                            className="submit-button mt-3 mb-3"
                            value="GỬI ĐI"
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ContactWithUs;