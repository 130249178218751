const ImageURL = ({ imageURL, style }) => {
	return (
		<div
			style={{
				backgroundSize: "contain",
				backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${imageURL})`,
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center",
				width: "100%",
				...style,
			}}
		/>
	);
};

export default ImageURL;
