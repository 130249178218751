import { useParams } from "react-router-dom";
import MediaWrapper from "./MediaWrapper";
import ReactPlayer from "react-player";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const MediaDetail = () => {
	let { slug } = useParams();

	const [media, setMedia] = useState({});

	useEffect(() => {
		document.title = "Media";
		getAllMedia(slug);
	}, [slug]);

	// API
	const getAllMedia = async (videoId) => {
		await axios
			.get(`/video/list`, {
				params: { id: videoId },
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					setMedia(res.data.video);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	return (
		<MediaWrapper>
			<div style={{ aspectRatio: "16/9", width: "100%" }}>
				<ReactPlayer url={media.embedCode} width="100%" height="100%" />
			</div>
		</MediaWrapper>
	);
};

export default MediaDetail;
