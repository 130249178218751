import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../../utils/yupGlobal";
import { toast } from "react-toastify";

import { Button, Form, Modal } from "react-bootstrap";

const MediaEditModal = ({ handleCloseModal, showModal, selectedMedia }) => {
	const [isSendForm, setIsSendForm] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const {
		reset,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(Schema),
	});

	useEffect(() => {
		getDetailMedia(selectedMedia);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Api
	const getDetailMedia = (videoId) => {
		setIsLoading(true);
		axios
			.get(`/video/show`, {
				params: {
					id: videoId,
				},
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					reset({
						name: res.data.video.videoTitleVi,
						link: res.data.video.embedCode,
					});
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const updateNewMedia = async (data) => {
		setIsSendForm(true);

		await axios
			.put(`/video/update`, {
				id: selectedMedia,
				videoTitleVi: data.name,
				embedCode: data.link,
				isType: "vOther",
			})
			.then((res) => {
				setIsSendForm(false);

				if (res.data.errCode === 0) {
					toast(res.data.message, {
						type: "success",
						autoClose: 1000,
					});
					handleCloseModal(true);
				} else {
					toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	return (
		<Modal
			show={showModal}
			onHide={() => handleCloseModal(false)}
			backdrop="static"
		>
			<Modal.Header closeButton={!isSendForm}>
				<Modal.Title>Cập nhật video</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form
					id="edit-media-hook-form"
					onSubmit={handleSubmit((data) => {
						updateNewMedia(data);
					})}
					className="mb-5"
				>
					<div className="mb-3">
						<Form.Floating>
							<Form.Control
								type="text"
								placeholder={`Tiêu đề`}
								name={`name`}
								{...register(`name`)}
							/>

							<Form.Label>
								{`Tiêu đề`}
								<i className="text-danger">*</i>
							</Form.Label>
						</Form.Floating>

						{errors[`name`] && (
							<p
								style={{
									fontSize: 13,
									color: "red",
									marginTop: 6,
								}}
							>
								{errors[`name`].message}
							</p>
						)}
					</div>

					<div className="mb-3">
						<Form.Floating>
							<Form.Control
								type="text"
								placeholder={`Link`}
								name={`link`}
								{...register(`link`)}
							/>

							<Form.Label>
								{`Link`}
								<i className="text-danger">*</i>
							</Form.Label>
						</Form.Floating>

						{errors[`link`] && (
							<p
								style={{
									fontSize: 13,
									color: "red",
									marginTop: 6,
								}}
							>
								{errors[`link`].message}
							</p>
						)}
					</div>
				</Form>
			</Modal.Body>

			<Modal.Footer>
				<Button
					type="button"
					variant="secondary"
					onClick={() => handleCloseModal(false)}
					disabled={isSendForm}
				>
					Đóng
				</Button>
				<Button
					type="submit"
					form="edit-media-hook-form"
					variant="primary"
					disabled={isSendForm || isLoading}
				>
					Cập nhật
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

const Schema = yup.object().shape({
	name: yup
		.string()
		.required("Không được bỏ trống")
		.test(
			"len",
			"Tiêu đề không dài quá 255 kí tự",
			(val) => val.length >= 0 && val.length <= 255,
		),
	link: yup.string().required("Không được bỏ trống"),
});

export default MediaEditModal;
