import { useEffect, useState } from "react";
import { Breadcrumb, Button, Container, Form } from "react-bootstrap";
import Icon from "../../../components/Icon";
import DataTables from "../../../components/DataTables";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import MemberAddModal from "./MemberAddModal";

const Member = () => {
	const [members, setMembers] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [keyword, setKeyword] = useState("");
	const [showAddModal, setShowAddModal] = useState(false);

	useEffect(() => {
		getAllMembers();
	}, []);

	// Api
	const getAllMembers = async (keyword) => {
		setIsLoading(true);
		await axios
			.get(`/user/list`, {
				params: {
					role: 1,
					keyword: keyword,
				},
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setMembers(res.data.users);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	const handleCloseAddModal = (isReload) => {
		setShowAddModal(false);
		if (isReload) {
			getAllMembers("");
		}
	};
	const handleShowAddModal = () => {
		setShowAddModal(true);
	};

	const columns = [
		{
			name: "Họ và tên",
			selector: (row) => row.fullname,
		},
		{
			name: "Số điện thoại",
			selector: (row) => row.phonenumber,
		},
		{
			name: "Email",
			selector: (row) => row.email,
		},
		{
			name: "Địa chỉ",
			selector: (row) => row.address,
			wrap: true,
		},
	];

	return (
		<Container fluid>
			<Breadcrumb className="mt-2">
				<Breadcrumb.Item active>Tổng quan</Breadcrumb.Item>
				<Breadcrumb.Item active>Thành viên</Breadcrumb.Item>
			</Breadcrumb>

			<div className="d-block d-md-flex justify-content-between">
				<div>
					<Form className="d-flex">
						<Form.Control
							type="search"
							placeholder={"Nhập tên thành viên"}
							className="me-2"
							aria-label="Search"
							value={keyword}
							onChange={(e) => setKeyword(e.target.value)}
						/>
						<Button
							variant="outline-secondary"
							onClick={() => getAllMembers(keyword)}
						>
							<Icon icon="magnifying-glass" />
						</Button>
					</Form>
				</div>
				<div className="mt-3 mt-md-0">
					<Button
						variant="primary"
						className="mt-2 mt-md-0"
						onClick={() => handleShowAddModal()}
					>
						<Icon icon="plus" /> Thêm mới
					</Button>
				</div>
			</div>

			{isLoading ? (
				<Loading />
			) : (
				<DataTables data={members} columns={columns} />
			)}

			{showAddModal ? (
				<MemberAddModal
					handleCloseModal={handleCloseAddModal}
					showModal={showAddModal}
				/>
			) : (
				""
			)}
		</Container>
	);
};

export default Member;
