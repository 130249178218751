import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const NotFound = ({ path }) => {
	const navigate = useNavigate();

	return (
		<Container className="d-flex justify-content-center align-items-center">
			<div className="text-center py-5">
				<h2>Không tìm thấy trang</h2>

				<Button
					size="lg"
					variant="secondary"
					className="mt-3"
					onClick={() => {
						navigate(path);
					}}
				>
					Trở lại
				</Button>
			</div>
		</Container>
	);
};

export default NotFound;
