import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { NEW } from "../../../routes/routerUrl";

import { Col, Row } from "react-bootstrap";
import NewWrapper from "./NewWrapper";
import ImageURL from "../../../components/ImageURL";
import TextEditor from "../../../components/TextEditor";
import Loading from "../../../components/Loading";
import NoData from "../../../components/NoData";

const NewDetail = () => {
	let { slug } = useParams();

	const [post, setPost] = useState({});
	const [relatedPosts, setRelatedPosts] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		getDetailPost(slug);
	}, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

	const getDetailPost = async (postSlug) => {
		setIsLoading(true);
		await axios
			.get(`/post/list`, {
				params: {
					postSlug: postSlug,
				},
			})
			.then(async (res) => {
				if (res.data.errCode === 0) {
					setPost(res.data.post);
					getAllPosts(res.data.post.categoryId, res.data.post.id);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllPosts = async (categoryId, postId) => {
		await axios
			.get(`/post/list`, {
				params: {
					cateId: categoryId,
					limit: 11,
				},
			})
			.then((res) => {
				setIsLoading(false);
				if (res.data.errCode === 0) {
					setRelatedPosts(
						res.data.posts.filter((post) => post.id !== postId),
					);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<NewWrapper>
			<h2>{post?.postTitleVi}</h2>

			<ImageURL
				style={{
					aspectRatio: "900/600",
					backgroundSize: "cover",
				}}
				className="my-3"
				imageURL={post?.imgURL}
			/>

			<div className="my-5">
				{post.postContentVi ? (
					<TextEditor
						disable
						hideToolbar={true}
						setContents={post.postContentVi}
						onChange={() => {}}
					/>
				) : (
					""
				)}
			</div>

			<Row>
				<h3 className="mb-3">Bài viết cùng chuyên mục</h3>

				{isLoading ? (
					<Loading />
				) : relatedPosts?.length > 0 ? (
					relatedPosts?.map((post) => {
						return (
							<Col
								key={post.id}
								xs="12"
								sm="6"
								md="4"
								className="mb-5 mb-md-4"
							>
								<Link
									to={`/${NEW}/${post.postSlug}/${post.postSlug}`}
								>
									<div
										style={{
											aspectRatio: "16/9",
											width: "100%",
										}}
									>
										<div
											key={post.id}
											style={{
												width: "100%",
												height: "10rem",
												backgroundSize: "cover",
												backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${post.imgURL})`,
												backgroundRepeat: "no-repeat",
												backgroundPosition: "center",
											}}
											className="youtube-parent d-flex justify-content-center align-items-center"
										/>
									</div>
								</Link>
								<div className="fw-bold my-2">
									<Link
										to={`/${NEW}/${post.postSlug}/${post.postSlug}`}
										className="text-black"
									>
										{post.postTitleVi}
									</Link>
								</div>
							</Col>
						);
					})
				) : (
					<NoData />
				)}
			</Row>
		</NewWrapper>
	);
};

export default NewDetail;
