import { useEffect } from "react";
import { Container } from "react-bootstrap";
import CTV1 from "../../../assets/image/ctv1.png";
import CTV2 from "../../../assets/image/ctv2.png";
import CTV3 from "../../../assets/image/ctv3.png";
import GENA1 from "../../../assets/image/gena1.jpg";
import GENA2 from "../../../assets/image/gena2.jpg";
import GENA3 from "../../../assets/image/gena3.png";
import GENA4 from "../../../assets/image/gena4.jpg";
import GENA5 from "../../../assets/image/gena5.jpg";
const Gena = () => {
  useEffect(() => {
    document.title = "Genna";
  }, []);

  return (
    <Container className="container-wrapper my-2">
      <div className="px-3" style={{ textAlign: "justify" }}>
        <div>
          <strong>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;Katec &nbsp;</span>
          </strong>
          <span>
            giới thiệu giải pháp phần mềm dành cho quản lý kinh doanh dịch vụ
            đại lý bảo hiểm nhân thọ. Với mục tiêu hỗ trợ tối ưu hóa quy trình
            làm việc, nâng cao hiệu quả quản lý và tăng cường sự hài lòng của
            khách hàng, phần mềm mang đến những tính năng vượt trội, đáp ứng
            toàn diện nhu cầu của các đại lý bảo hiểm.
          </span>
        </div>
        <div>
          <strong>
            <span>Tính năng nổi bật</span>
          </strong>
        </div>
        <div>
          <strong>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;1.Quản lý phân quyền nhân viên:</span>
          </strong>
        </div>
        <ul type="disc">
          <li>
            <span>
              {" "}
              Theo dõi và phân quyền truy cập cho từng nhân viên, đảm bảo an
              toàn và bảo mật thông tin.
            </span>
          </li>{" "}
          <li>
            <span>
              {" "}
              Quản lý danh sách nhân viên một cách khoa học và tiện lợi, với khả
              năng xuất thông tin ra file Excel phục vụ cho công tác báo cáo và
              lưu trữ.
            </span>
          </li>
        </ul>
        <img
          src={GENA1}
          alt=""
          className="my-2"
          style={{ height: "100%", width: "100%" }}
        />
        <div>
          <strong>
            <span> &nbsp;&nbsp;&nbsp;&nbsp;2. Quản lý khen thưởng</span>
          </strong>
        </div>
        <ul type="disc">
          <li>
            <span>
              Ghi nhận và theo dõi các khen thưởng dành cho nhân viên, tạo động
              lực và khuyến khích sự phát triển cá nhân.
            </span>
          </li>{" "}
          <li>
            <span>
              {" "}
              Lưu trữ thông tin chi tiết về các khen thưởng, đảm bảo minh bạch
              và công bằng.
            </span>
          </li>
        </ul>
        <img
          src={GENA2}
          alt=""
          className="my-2"
          style={{ height: "100%", width: "100%" }}
        />
        <div
          class="se-component se-image-container __se__float- __se__float-none"
          contenteditable="false"
        ></div>
        <div></div>
        <div>
          <strong>
            <span> &nbsp;&nbsp;&nbsp;&nbsp;3. Quản lý sản phẩm:</span>
          </strong>
        </div>

        <ul type="disc">
          <li>
            <span>
              {" "}
              Lưu trữ thông tin chi tiết về các sản phẩm bảo hiểm, giúp đại lý
              dễ dàng theo dõi và quản lý danh mục sản phẩm.{" "}
            </span>
          </li>{" "}
          <li>
            <span>
              {" "}
              Theo dõi chi tiết về sản phẩm, hỗ trợ tối đa việc tư vấn và giới
              thiệu sản phẩm tới khách hàng.
            </span>
          </li>
        </ul>
        <div>
          <strong>
            <span> &nbsp;&nbsp;&nbsp;&nbsp;4. Quản lý hợp đồng:</span>
          </strong>
        </div>
        <ul type="disc">
          <li>
            <span>
              {" "}
              Theo dõi thông tin hợp đồng với khách hàng, đảm bảo quy trình ký
              kết và thực hiện hợp đồng diễn ra suôn sẻ.
            </span>
          </li>{" "}
          <li>
            <span>
              {" "}
              Khả năng xuất thông tin hợp đồng ra file Excel, phục vụ công tác
              báo cáo và quản lý.
            </span>
          </li>
        </ul>
        <img
          src={GENA3}
          alt=""
          className="my-2"
          style={{ height: "100%", width: "100%" }}
        />
        <div>
          <strong>
            <span>
              {" "}
              &nbsp;&nbsp;&nbsp;&nbsp;5. Xử lý thu nhập theo chính sách phát
              triển kinh doanh:
            </span>
          </strong>
        </div>
        <ul type="disc">
          <li>
            <span>
              Tính toán thu nhập dựa trên chính sách của công ty, đảm bảo chính
              xác và công bằng.
            </span>
          </li>{" "}
          <li>
            <span>
              {" "}
              Theo dõi thu nhập của từng đại lý, giúp họ nắm bắt rõ ràng về tình
              hình thu nhập và định hướng phát triển.
            </span>
          </li>
        </ul>
        <div>
          <strong>
            <span> &nbsp;&nbsp;&nbsp;&nbsp;6. Quản lý thu nhập:</span>
          </strong>
        </div>
        <ul type="disc">
          <li>
            <span>
              Lưu trữ thông tin về thu nhập của đại lý, giúp quản lý dễ dàng và
              chính xác.
            </span>
          </li>{" "}
          <li>
            <span>
              Tạo báo cáo về thu nhập, hỗ trợ công tác quản lý và ra quyết định.
            </span>
          </li>
        </ul>
        <img
          src={GENA4}
          alt=""
          className="my-2"
          style={{ height: "100%", width: "100%" }}
        />
        <div>
          <strong>
            <span> &nbsp;&nbsp;&nbsp;&nbsp;7. Quản lý thông báo:</span>
          </strong>
        </div>
        <ul type="disc">
          <li>
            <span>
              Gửi và theo dõi thông báo đến đại lý, đảm bảo thông tin được
              truyền tải nhanh chóng và chính xác
            </span>
          </li>{" "}
          <li>
            <span>
              Lưu trữ lịch sử thông báo, giúp theo dõi và quản lý thông tin hiệu
              quả.
            </span>
          </li>
        </ul>

        <div
          class="se-component se-image-container __se__float- __se__float-none"
          contenteditable="false"
        ></div>
        <div></div>
        <div>
          <strong>
            <span> &nbsp;&nbsp;&nbsp;&nbsp;8. Quản lý thông tin đăng ký"</span>
          </strong>
        </div>
        <ul type="disc">
          <li>
            <span>
              {" "}
              Theo dõi thông tin đăng ký của đại lý, giúp quản lý quy trình đăng
              ký một cách khoa học.
            </span>
          </li>{" "}
          <li>
            <span>
              {" "}
              Lưu trữ chi tiết về đăng ký, đảm bảo tính chính xác và minh bạch.
            </span>
          </li>{" "}
        </ul>

        <img
          src={GENA5}
          alt=""
          className="my-2"
          style={{ height: "100%", width: "100%" }}
        />
        <div
          class="se-component se-image-container __se__float- __se__float-none"
          contenteditable="false"
        ></div>
        <div></div>
        <div>
          <strong>
            <span>*. Lợi ích khi sử dụng phần mềm</span>
          </strong>
        </div>

        <ul type="disc">
          <li>
            <strong>
              <span>Tối ưu hóa quản lý:</span>
            </strong>
            <span>
              {" "}
              Phần mềm giúp đơn giản hóa và tự động hóa các quy trình quản lý,
              tiết kiệm thời gian và nguồn lực.
            </span>
          </li>
          <li>
            <strong>
              <span>Tăng cường hiệu quả:</span>
            </strong>
            <span>
              Với hệ thống quản lý thông minh, phần mềm giúp nâng cao hiệu suất
              làm việc của nhân viên và toàn bộ đại lý.
            </span>
          </li>
          <li>
            <strong>
              <span>Đảm bảo bào mật thông tin:</span>
            </strong>
            <span>
              Hệ thống phân quyền và bảo mật thông tin chặt chẽ, bảo vệ dữ liệu
              quan trọng của doanh nghiệp
            </span>
          </li>
          <li>
            <strong>
              <span>Hỗ trợ quyết định:</span>
            </strong>
            <span>
              Các báo cáo và thống kê chi tiết giúp quản lý đưa ra các quyết
              định kinh doanh chính xác và kịp thời.
            </span>
          </li>
        </ul>
        <div>
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;Phần mềm quản lý kinh doanh dịch vụ đại lý
            bảo hiểm nhân thọ của Katec là giải pháp hoàn hảo cho mọi đại lý bảo
            hiểm mong muốn nâng cao chất lượng dịch vụ và hiệu quả quản lý. Hãy
            liên hệ với chúng tôi để biết thêm chi tiết và trải nghiệm sự khác
            biệt từ công nghệ.
          </span>
        </div>
      </div>
    </Container>
  );
};

export default Gena;
