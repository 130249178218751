import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Breadcrumb, Button, Container, Form } from "react-bootstrap";
import TextEditor from "../../components/TextEditor";

const Introduction = () => {
	const [content, setContent] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [isSendForm, setIsSendForm] = useState(false);

	useEffect(() => {
		getDetailNew();
	}, []);

	const getDetailNew = () => {
		setIsLoading(true);
		axios
			.get(`/post/show`, {
				params: {
					id: Number(process.env.REACT_APP_INTRODUCTION_POST),
				},
			})
			.then(async (res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setContent(res.data.post.postContentVi);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const updateNew = async () => {
		setIsSendForm(true);

		var bodyFormData = new FormData();

		bodyFormData.append(
			"id",
			Number(process.env.REACT_APP_INTRODUCTION_POST),
		);
		bodyFormData.append(
			"categoryId",
			process.env.REACT_APP_INTRODUCTION_ID,
		);
		bodyFormData.append("postTitleVi", "Giới thiệu");
		bodyFormData.append("postDescVi", "");
		bodyFormData.append("postContentVi", content);
		bodyFormData.append("postSlug", "gioi-thieu");
		bodyFormData.append("isStatus", "S1");

		await axios({
			method: "put",
			url: "post/update",
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then((res) => {
				setIsSendForm(false);
				if (res.data.errCode === 0) {
					toast(res.data.message, {
						type: "success",
						autoClose: 1000,
					});
				} else {
					toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<Container fluid>
			<Breadcrumb className="mt-2">
				<Breadcrumb.Item active>Tổng quan</Breadcrumb.Item>
				<Breadcrumb.Item active>Giới thiệu</Breadcrumb.Item>
			</Breadcrumb>

			<Form
				onSubmit={(e) => {
					e.preventDefault();
					updateNew();
				}}
			>
				<TextEditor
					setContents={content}
					onChange={(value) => setContent(value)}
					placeholder={"Nội dung"}
					style={{ border: "1px solid #dadada", padding: 16 }}
				/>

				<Button
					type="submit"
					variant="primary"
					className="mt-3"
					disabled={isSendForm || isLoading}
				>
					Cập nhật
				</Button>
			</Form>
		</Container>
	);
};

export default Introduction;
