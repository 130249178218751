import { useEffect } from "react";
import { Container } from "react-bootstrap";
import CTV1 from "../../../assets/image/ctv1.png";
import CTV2 from "../../../assets/image/ctv2.png";
import CTV3 from "../../../assets/image/ctv3.png";
import GENA1 from "../../../assets/image/haivan1.png";
import GENA2 from "../../../assets/image/haivan2.png";
import GENA3 from "../../../assets/image/haivan3.png";
const HaiVan = () => {
  useEffect(() => {
    document.title = "Hải vân";
  }, []);

  return (
    <Container className="container-wrapper my-2">
      <div className="px-3" style={{ textAlign: "justify" }}>
        <div>
          <strong>
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;Phần mềm quản lý kinh doanh dịch vụ biển
              hiệu quảng cáo ngoài trời
            </span>
          </strong>
          <span>
            – một giải pháp toàn diện giúp doanh nghiệp tối ưu hóa quy trình
            quản lý và nâng cao hiệu quả hoạt động.
          </span>
        </div>
        <div>
          <strong>
            <span>Tính năng nổi bật của phần mềm</span>
          </strong>
        </div>
        <div>
          <strong>
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;1. Quản lý nhân viên theo chức vụ
            </span>
          </strong>
        </div>
        <ul type="disc">
          <li>
            <strong>
              <span>Theo dõi thông tin và phân quyền:</span>
            </strong>
            <span>
              {" "}
              Phần mềm cho phép theo dõi thông tin chi tiết và phân quyền cho
              từng nhân viên dựa trên chức vụ của họ, giúp đảm bảo mọi hoạt động
              được kiểm soát chặt chẽ và hiệu quả.
            </span>
          </li>{" "}
          <li>
            <strong>
              <span>Quản lý danh sách nhân viên:</span>
            </strong>
            <span>
              {" "}
              Tổ chức và quản lý danh sách nhân viên theo chức vụ một cách khoa
              học và dễ dàng.
            </span>
          </li>
        </ul>

        <div>
          <strong>
            <span>
              {" "}
              &nbsp;&nbsp;&nbsp;&nbsp;2. Quản lý danh sách khách hàng
            </span>
          </strong>
        </div>
        <ul type="disc">
          <li>
            <strong>
              <span>Lưu trữ thông tin chi tiết:</span>
            </strong>
            <span>
              {" "}
              Mọi thông tin liên quan đến khách hàng đều được lưu trữ một cách
              chi tiết và an toàn, giúp doanh nghiệp dễ dàng truy cập và sử dụng
              khi cần thiết.
            </span>
          </li>{" "}
          <li>
            <strong>
              <span>Xuất dữ liệu ra file Excel:</span>
            </strong>
            <span>
              {" "}
              Dễ dàng xuất dữ liệu khách hàng ra file Excel để sử dụng cho các
              mục đích báo cáo và phân tích.
            </span>
          </li>
        </ul>
        <img
          src={GENA1}
          alt=""
          className="my-2"
          style={{ height: "100%", width: "100%" }}
        />
        <div
          class="se-component se-image-container __se__float- __se__float-none"
          contenteditable="false"
        ></div>
        <div></div>
        <div>
          <strong>
            <span> &nbsp;&nbsp;&nbsp;&nbsp;3. Quản lý hợp đồng</span>
          </strong>
        </div>

        <ul type="disc">
          <li>
            <strong>
              <span>Theo dõi thông tin hợp đồng:</span>
            </strong>
            <span>
              {" "}
              Phần mềm giúp theo dõi thông tin chi tiết về các hợp đồng đã ký
              kết với khách hàng, từ đó giúp doanh nghiệp quản lý các cam kết và
              thời hạn hiệu quả hơn.
            </span>
          </li>{" "}
          <li>
            <strong>
              <span>Xuất dữ liệu hợp đồng:</span>
            </strong>
            <span>
              {" "}
              Chức năng xuất dữ liệu hợp đồng ra file Excel giúp doanh nghiệp
              lưu trữ và quản lý thông tin một cách dễ dàng và tiện lợi.
            </span>
          </li>
        </ul>
        <div>
          <strong>
            <span> &nbsp;&nbsp;&nbsp;&nbsp;4. Quản lý sản phẩm</span>
          </strong>
        </div>
        <ul type="disc">
          <li>
            <strong>
              <span>Lưu trữ thông tin sản phẩm:</span>
            </strong>
            <span>
              {" "}
              Phần mềm cung cấp khả năng lưu trữ thông tin chi tiết về các sản
              phẩm quảng cáo, giúp doanh nghiệp quản lý danh mục sản phẩm một
              cách hiệu quả.{" "}
            </span>
          </li>{" "}
          <li>
            <strong>
              <span>Xuất dữ liệu sản phẩm:</span>
            </strong>
            <span>
              {" "}
              Doanh nghiệp có thể xuất dữ liệu sản phẩm ra file Excel và
              PowerPoint, hỗ trợ quá trình thuyết trình và báo cáo.
            </span>
          </li>
        </ul>
        <div>
          <strong>
            <span> &nbsp;&nbsp;&nbsp;&nbsp;5. Quản lý hóa đơn</span>
          </strong>
        </div>
        <ul type="disc">
          <li>
            <strong>
              <span> Theo dõi thông tin hóa đơn:</span>
            </strong>
            <span>
              {" "}
              Phần mềm cho phép theo dõi thông tin chi tiết về các hóa đơn thanh
              toán, giúp doanh nghiệp quản lý tài chính một cách chính xác và
              minh bạch.
            </span>
          </li>{" "}
          <li>
            <strong>
              <span>Xuất dữ liệu hóa đơn: </span>
            </strong>
            <span>
              {" "}
              Chức năng xuất dữ liệu hóa đơn ra file Excel giúp doanh nghiệp lưu
              trữ và phân tích thông tin một cách tiện lợi.
            </span>
          </li>
        </ul>
        <div>
          <strong>
            <span>
              {" "}
              &nbsp;&nbsp;&nbsp;&nbsp;6. Quản lý giấy phép quảng cáo và xây dựng
            </span>
          </strong>
        </div>
        <ul type="disc">
          <li>
            <strong>
              <span>Lưu trữ thông tin giấy phép:</span>
            </strong>
            <span>
              {" "}
              Phần mềm cung cấp khả năng lưu trữ thông tin chi tiết về các giấy
              phép quảng cáo và xây dựng, giúp doanh nghiệp tuân thủ các quy
              định pháp luật và quản lý giấy tờ hiệu quả.
            </span>
          </li>{" "}
          <li>
            <strong>
              <span> Xuất dữ liệu giấy phép:</span>
            </strong>
            <span>
              {" "}
              Dễ dàng xuất dữ liệu giấy phép ra file Excel để phục vụ cho các
              mục đích kiểm tra và báo cáo.
            </span>
          </li>
        </ul>
        <div>
          <strong>
            <span>
              {" "}
              &nbsp;&nbsp;&nbsp;&nbsp;7. Báo cáo doanh thu và công nợ
            </span>
          </strong>
        </div>
        <ul type="disc">
          <li>
            <strong>
              <span>Tạo báo cáo doanh thu và công nợ:</span>
            </strong>
            <span>
              {" "}
              Phần mềm hỗ trợ tạo các báo cáo chi tiết về doanh thu và công nợ,
              giúp doanh nghiệp nắm bắt tình hình tài chính một cách toàn diện.
            </span>
          </li>{" "}
          <li>
            <strong>
              <span> Theo dõi tình hình tài chính:</span>
            </strong>
            <span>
              {" "}
              Chức năng theo dõi tình hình tài chính giúp doanh nghiệp quản lý
              tài chính hiệu quả và đưa ra các quyết định chiến lược kịp thời.
            </span>
          </li>
        </ul>
        <img
          src={GENA2}
          alt=""
          className="my-2"
          style={{ height: "100%", width: "100%" }}
        />
        <div
          class="se-component se-image-container __se__float- __se__float-none"
          contenteditable="false"
        ></div>
        <div></div>
        <div>
          <strong>
            <span> &nbsp;&nbsp;&nbsp;&nbsp;8. Quản lý công nợ</span>
          </strong>
        </div>
        <ul type="disc">
          <li>
            <strong>
              <span>Theo dõi công nợ:</span>
            </strong>
            <span>
              {" "}
              Phần mềm cho phép theo dõi công nợ của khách hàng, từ đó nhắc nhở
              khách hàng thanh toán đúng hạn, giúp doanh nghiệp quản lý dòng
              tiền một cách hiệu quả.
            </span>
          </li>{" "}
        </ul>
        <img
          src={GENA3}
          alt=""
          className="my-2"
          style={{ height: "100%", width: "100%" }}
        />
        <div
          class="se-component se-image-container __se__float- __se__float-none"
          contenteditable="false"
        ></div>
        <div></div>
        <div>
          <strong>
            <span>Lợi ích của phần mềm</span>
          </strong>
        </div>
        <div>
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;Phần mềm quản lý kinh doanh dịch vụ biển
            hiệu quảng cáo ngoài trời của Katec mang đến nhiều lợi ích vượt trội
            cho doanh nghiệp:
          </span>
        </div>
        <ul type="disc">
          <li>
            <strong>
              <span>Tối ưu hóa quy trình quản lý:</span>
            </strong>
            <span>
              {" "}
              Giúp doanh nghiệp tiết kiệm thời gian và nguồn lực thông qua việc
              tự động hóa các quy trình quản lý.
            </span>
          </li>
          <li>
            <strong>
              <span>Nâng cao hiệu quả làm việc:</span>
            </strong>
            <span>
              {" "}
              Giúp nhân viên làm việc hiệu quả hơn nhờ vào khả năng quản lý
              thông tin chi tiết và dễ dàng truy cập.
            </span>
          </li>
          <li>
            <strong>
              <span>Cải thiện quan hệ khách hàng:</span>
            </strong>
            <span>
              {" "}
              Giúp doanh nghiệp quản lý thông tin khách hàng một cách chi tiết,
              từ đó nâng cao chất lượng dịch vụ và cải thiện quan hệ với khách
              hàng.
            </span>
          </li>
          <li>
            <strong>
              <span>Hỗ trợ ra quyết định:</span>
            </strong>
            <span>
              {" "}
              Cung cấp các báo cáo chi tiết và kịp thời, giúp doanh nghiệp đưa
              ra các quyết định chiến lược chính xác.
            </span>
          </li>
        </ul>
        <div>
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;Công ty Katec cam kết mang đến cho doanh
            nghiệp của bạn một giải pháp quản lý toàn diện và hiệu quả, giúp bạn
            không chỉ tối ưu hóa hoạt động kinh doanh mà còn nâng cao sức cạnh
            tranh trên thị trường. Hãy trải nghiệm ngay phần mềm quản lý kinh
            doanh dịch vụ biển hiệu quảng cáo ngoài trời của Katec để thấy sự
            khác biệt!
          </span>
        </div>
      </div>
    </Container>
  );
};

export default HaiVan;
