import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../../utils/yupGlobal";
import { toast } from "react-toastify";

import { Button, Form, Modal } from "react-bootstrap";
import convertToSlug from "../../../utils/slugFormatter";

const CategoryEditModal = ({
	handleCloseModal,
	showModal,
	selectedCategory,
}) => {
	const [slug, setSlug] = useState("");

	const [isSendForm, setIsSendForm] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errMessage, setErrMessage] = useState({
		cateSlug: undefined,
	});

	const {
		reset,
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(Schema),
	});

	useEffect(() => {
		getDetailCategory(selectedCategory);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Api
	const getDetailCategory = (categoryId) => {
		setIsLoading(true);
		axios
			.get(`/category/show`, {
				params: {
					id: categoryId,
					isType: "cPro",
				},
			})
			.then(async (res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					reset({
						name: res.data.category.cateNameVi,
					});
					setSlug(res.data.category.cateSlug);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const updateNewCategory = async (data) => {
		setIsSendForm(true);

		var bodyFormData = new FormData();
		bodyFormData.append("id", selectedCategory);
		bodyFormData.append("cateNameVi", data.name);
		bodyFormData.append("cateSlug", slug);
		bodyFormData.append("isType", "cPro");
		bodyFormData.append("cateParentId", process.env.REACT_APP_CATEGORY_ID);
		bodyFormData.append("isStatus", "S1");

		await axios({
			method: "put",
			url: "category/update",
			data: bodyFormData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then((res) => {
				setIsSendForm(false);

				if (res.data.errCode === 0) {
					toast(res.data.message, {
						type: "success",
						autoClose: 1000,
					});
					handleCloseModal(true);
				} else {
					setErrMessage(res.data.message);
					toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	return (
		<Modal
			show={showModal}
			onHide={() => handleCloseModal(false)}
			backdrop="static"
		>
			<Modal.Header closeButton={!isSendForm}>
				<Modal.Title>Cập nhật danh mục </Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form
					id="edit-category-hook-form"
					onSubmit={handleSubmit((data) => {
						updateNewCategory(data);
					})}
					className="mb-5"
				>
					<div className="mb-3">
						<Form.Floating>
							<Form.Control
								type="text"
								placeholder={`Tên danh mục`}
								name={`name`}
								{...register(`name`, {
									onChange: () => {
										setSlug(
											convertToSlug(getValues("name")),
										);
									},
								})}
							/>

							<Form.Label>
								{`Tên danh mục`}
								<i className="text-danger">*</i>
							</Form.Label>
						</Form.Floating>

						{errors[`name`] && (
							<p
								style={{
									fontSize: 13,
									color: "red",
									marginTop: 6,
								}}
							>
								{errors[`name`].message}
							</p>
						)}
					</div>

					<div className="mb-3">
						<Form.Floating>
							<Form.Control
								type="text"
								placeholder="Slug"
								name="slug"
								disabled
								value={slug}
							/>

							<Form.Label>
								Slug
								<i className="text-danger">*</i>
							</Form.Label>
						</Form.Floating>
						{errMessage.cateSlug && (
							<p
								style={{
									fontSize: 13,
									color: "red",
									marginTop: 6,
								}}
							>
								{errMessage.cateSlug}
							</p>
						)}
					</div>
				</Form>
			</Modal.Body>

			<Modal.Footer>
				<Button
					type="button"
					variant="secondary"
					onClick={() => handleCloseModal(false)}
					disabled={isSendForm}
				>
					Đóng
				</Button>
				<Button
					type="submit"
					form="edit-category-hook-form"
					variant="primary"
					disabled={isSendForm || isLoading}
				>
					Cập nhật
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

const Schema = yup.object().shape({
	name: yup
		.string()
		.required("Không được bỏ trống")
		.test(
			"len",
			"Tên chủ đề không dài quá 255 kí tự",
			(val) => val.length >= 0 && val.length <= 255,
		),
});

export default CategoryEditModal;
