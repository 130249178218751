import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

import "./Style.css";
import axios from "axios";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";
import { faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons";
import ZaloLogo from "../../assets/image/zalo-logo.png";

function MasterLayout() {
  const [showSideBar, setShowSideBar] = useState(false);
  const [shop, setShop] = useState({});

  useEffect(() => {
    getSetting();
  }, []);

  const getSetting = async () => {
    await axios
      .get(`/shop/detail`)
      .then((res) => {
        if (res.data.errCode === 0) {
          setShop(res.data.shop);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Navbar
        setShowSideBar={setShowSideBar}
        showSideBar={showSideBar}
        shop={shop}
      />

      <Sidebar
        setShowSideBar={setShowSideBar}
        showSideBar={showSideBar}
        shop={shop}
      />

      <div onClick={() => setShowSideBar(false)}>
        <div className={`${showSideBar ? "curtain active" : "curtain"}`} />

        <Outlet />

        <div
          style={{
            display: "inline-block",
            position: "fixed",
            bottom: "50px",
            right: "2rem",
            opacity: 0.8,
            zIndex: 9999,
          }}
        >
          {shop.shopDescVi ? (
            <>
              <Link
                to={`https://www.facebook.com/messages/t/${shop.shopDescVi.substring(
                  shop.shopDescVi.indexOf("=") + 1,
                  shop.shopDescVi.lastIndexOf("&")
                )}`}
                target="_blank"
                className="d-block"
                title="Nhắn tin FaceBook"
              >
                <FontAwesomeIcon
                  icon={faFacebookMessenger}
                  size="2xl"
                  style={{
                    height: "40px",
                    width: "40px",
                    color: "blue !important",
                    opacity: 0.9,
                  }}
                  shake
                />

                <span></span>
              </Link>
              <br />{" "}
            </>
          ) : (
            ""
          )}

          <Link
            to={`https://zalo.me/0889881010`}
            target="_blank"
            className="d-block"
            title="Nhắn tin Zalo"
          >
            <img
              src={ZaloLogo}
              alt=""
              style={{ width: "40px", height: "40px" }}
            />

            <span></span>
          </Link>
          <br />
          <div
            title="Về đầu trang"
            id="top-up"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <FontAwesomeIcon
              icon={faArrowAltCircleUp}
              size="2xl"
              style={{
                textAlign: "center",
                height: "40px",
                width: "40px",
                cursor: "pointer",
              }}
              className="text-info"
            />
          </div>
        </div>
      </div>

      <Footer shop={shop} />
    </div>
  );
}

export default MasterLayout;
