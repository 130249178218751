import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

const DropdownMenu = (children) => {
    const items = Array.from(children.children);
    const location = useLocation();
    return (
        <Col
            className="menu-dropdown"
        >
            {items.map((item) => {
                return (
                    <Row>
                        <Link
                            to={item.link}
                            className={location.pathname === "/" && item.link === "/"
                                ? "navbar-text-active text-uppercase menu-dropdown-item"
                                : location.pathname.startsWith(`/${item.link}`)
                                    ? "navbar-text-active text-uppercase menu-dropdown-item"
                                    : "navbar-text text-uppercase menu-dropdown-item"}
                        >
                            {item.title}
                        </Link>
                    </Row>
                );
            })}
        </Col>
    );
};

const NavbarItem = ({ item }) => {
    const location = useLocation();

    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const handleMouseEnter = () => {
        setDropdownVisible(true);
    };

    const handleMouseLeave = () => {
        setDropdownVisible(false);
    };

    const { children } = item;
    if (children === undefined) {
        return (
            <React.Fragment key={item.id}>
                <div className="mx-3" />
                <Link
                    to={item.link}
                    className={
                        location.pathname === "/" && item.link === "/"
                            ? "navbar-text-active text-uppercase"
                            : location.pathname.startsWith(`/${item.link}`)
                                ? "navbar-text-active text-uppercase"
                                : "navbar-text text-uppercase"
                    }
                >
                    {item.title}
                </Link>
            </React.Fragment>
        );
    }
    return (
        <React.Fragment key={item.id}>
            <div className="mx-3" />
            <Link
                to={item.link}
                className={
                    location.pathname === "/" && item.link === "/"
                        ? "navbar-text-active text-uppercase dropdown-toggle "
                        : location.pathname.startsWith(`/${item.link}`)
                            ? "navbar-text-active text-uppercase dropdown-toggle"
                            : "navbar-text text-uppercase dropdown-toggle"
                }
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {item.title}
                {isDropdownVisible && <DropdownMenu children={children} />}
            </Link>
        </React.Fragment>
    );
}

export default NavbarItem;