import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImgField1 from "../../../assets/image/img-field-1.png";
import ImgField2 from "../../../assets/image/img-field-2.png";
import ImgField3 from "../../../assets/image/img-field-3.png";

import Ctv from "../../../assets/image/ctv.png";
import haivan from "../../../assets/image/haivan.png";
import gena from "../../../assets/image/gena.png";

import Select from "react-select";

const itemsPerPage = 16;

const Product = () => {
  const [categoryOptions, setCategoryOptions] = useState([
    { id: 0, cateNameVi: "Tất cả", cateSlug: "tat-ca" },
  ]);
  const [selectedCategory, setSelectedCategory] = useState({
    id: 0,
    cateNameVi: "Tất cả",
    cateSlug: "tat-ca",
  });

  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);

  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const fieldActivities = [
    {
      id: 2,
      title:
        "­ Quản lý suất ăn và định lượng dinh dưỡng cho trẻ mầm non, tiểu học; Suất ăn khu công nghiệp, viện dưỡng lão, trại cai nghiện",
      imgUrl: ImgField2,
      url: "https://kafood.vn/",
    },
    {
      id: 3,
      title:
        "Quản lý Quán Café, ăn uống, shop, nhà sách, tạp hóa, nhà phân phối",
      imgUrl: ImgField3,
      url: "https://kaviet.vn",
    },
    {
      id: 1,
      title:
        "Thiết kế, xây dựng website chuyên lĩnh vực bán hàng, giới thiệu sản phẩm với độ an toàn cao,chuẩn SEO",
      imgUrl: ImgField1,
      url: "https://thietkeweb.katec.vn/",
    },
    {
      id: 5,
      title: "Quản lý dịch vụ quảng cáo của Đài phát thanh và Truyền hình",
      imgUrl: Ctv,
      url: "/gioi-thieu-ctv",
      // checkModal: true,
    },

    {
      id: 6,
      title: "Quản lý kinh doanh dịch vụ biển hiệu quảng cáo ngoài trời ",
      imgUrl: haivan,
      url: "/gioi-thieu-haivan",
      // checkModal: true,
    },
    {
      id: 4,
      title: "Quản lý kinh doanh dịch vụ Đại lý Bảo hiểm nhân thọ",
      imgUrl: gena,
      url: "/gioi-thieu-gena",
    },
  ];
  const FieldActivityItem = ({ item }) => {
    const { imgUrl, title, url } = item;
    return (
      <Link to={url} className="field-activity-title" target="_blank">
        <img
          src={imgUrl}
          alt=""
          class="field-activity-item"
          style={{ height: "auto", width: "100%" }}
        ></img>

        {/* <div
        className="field-activity-item"
        style={{ backgroundImage: `url(${imgUrl})` }}
      >
        <div className="field-activity-fill">
    
   
        </div>
      </div> */}

        <div
          style={{ color: "blue", background: "#f2f2f2", textAlign: "justify" }}
        >
          {title}
        </div>
      </Link>
    );
  };
  useEffect(() => {
    document.title = "Sản phẩm";
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {}, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  // API

  // API

  return (
    <Container className="container-wrapper">
      <div className="d-block d-md-flex justify-content-between my-3">
        <Col xs="12" md="4">
          <Select
            options={categoryOptions}
            placeholder={`Chọn danh mục sản phẩm`}
            noOptionsMessage={() => "Không có lựa chọn"}
            getOptionLabel={(option) => option.cateNameVi}
            getOptionValue={(option) => option.id}
            value={selectedCategory}
            onChange={(choice) => {
              setSelectedCategory(choice);
              setCurrentPage(1);
              setKeyword("");
            }}
            menuPortalTarget={document.body}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
              }),
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                paddingTop: 0,
                paddingBottom: 0,
              }),
            }}
          />
        </Col>

        <Form
          onSubmit={(e) => {
            e.preventDefault();

            setCurrentPage(1);
          }}
        >
          <Form.Control
            placeholder="Tìm kiếm sản phẩm"
            style={{ borderRadius: 0 }}
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
            className="mt-3 mt-md-0"
          />
        </Form>
      </div>{" "}
      <Row>
        {fieldActivities.map((item) => {
          return (
            <Col xs="12" md="4" className={"py-3"}>
              <FieldActivityItem item={item} />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default Product;
