import * as yup from "yup";

const REGEX_PHONE =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const REGEX_PASSWORD = /^(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z_.\-@]{6,32}$/;
const REGEX_ONLY_NUMBER = /^\d+$/;

yup.addMethod(yup.string, "phone", function (message) {
	return this.matches(REGEX_PHONE, {
		message,
		excludeEmptyString: true,
	});
});

yup.addMethod(yup.string, "password", function (message) {
	return this.matches(REGEX_PASSWORD, {
		message,
		excludeEmptyString: true,
	});
});

yup.addMethod(yup.string, "onlyNumber", function (message) {
	return this.matches(REGEX_ONLY_NUMBER, {
		message,
		excludeEmptyString: true,
	});
});

export default yup;
