import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../../assets/image/logo.jpg";
import Icon from "../../components/Icon";
import NavbarItem from "./NavbarItem";
import { Link, useLocation } from "react-router-dom";

// import { INTRODUCTION, PRODUCT, SOLUTION, MEDIA, CONTACT, TOPIC } from "../../routes/routerUrl";
import { INTRODUCTION, PRODUCT, MEDIA, CONTACT, TOPIC } from "../../routes/routerUrl";

import React, { useState } from "react";

import { faFacebook, faYoutube } from "@fortawesome/free-brands-svg-icons";

const navbarItems = [
	{
		id: 1,
		title: "Trang chủ",
		link: "/",
	},
	{
		id: 2,
		title: "Giới thiệu",
		link: INTRODUCTION,
	},
	{
		id: 3,
		title: "Sản phẩm",
		link: PRODUCT,
	},
	{
		id: 5,
		title: "Tin tức",
		link: TOPIC,
	},
	{
		id: 6,
		title: "Tuyển dụng",
		link: MEDIA,
	},
	{
		id: 7,
		title: "Liên hệ",
		link: CONTACT,
	},
];

function UserNavBar({ setShowSideBar, showSideBar, shop }) {
	return (
		<>
			<Navbar expand="lg" className="nav-height w-100 px-0">
				<Container className="container-wrapper desktop-nav ">
					<Link
						to="/"
						style={{
							textDecoration: "none"
						}}
						className="align-items-center"
					>
						<div className="logo">
							<img src={Logo} alt="" class="custom-image" style={{ width: "30%", height: "auto" }}></img>
						</div>
					</Link>

					<div className="d-flex justify-content-center align-items-center">
						<Navbar.Collapse id="navbarScroll">
							<Nav className="my-2 my-lg-0 align-items-center" navbarScroll>
								{navbarItems.map((item) => {
									return (
										<NavbarItem item={item} />
									);
								})}
							</Nav>
						</Navbar.Collapse>
					</div>
				</Container>

				<Container className="mobile-nav justify-content-between align-items-center w-100">
					<Link
						to="/"
						style={{
							textDecoration: "none"
						}}
					>
						<div className="logo">
							<img src={Logo} alt="" class="custom-image" style={{ width: "40%", height: "auto" }}></img>
						</div>
					</Link>

					<div className="d-flex align-items-center">
						<div
							onClick={() => {
								setShowSideBar(!showSideBar);
							}}
							className="toggle-button"
						>
							<Icon icon="bars" />
						</div>
					</div>
				</Container>
			</Navbar>
		</>
	);
}

export default UserNavBar;
