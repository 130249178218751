import { useEffect } from "react";
import { Container } from "react-bootstrap";
import CTV1 from "../../../assets/image/ctv1.png";
import CTV2 from "../../../assets/image/ctv2.png";
import CTV3 from "../../../assets/image/ctv3.png";
const CTV = () => {
  useEffect(() => {
    document.title = "CTV";
  }, []);

  return (
    <Container className="container-wrapper my-2">
      {/* <Section title="Giới thiệu ctv"></Section> */}

      <div className="px-3" style={{ textAlign: "justify" }}>
        <div>
          <strong>
            &nbsp;&nbsp;&nbsp;&nbsp;Công ty Cổ phần Công nghệ Katec
          </strong>{" "}
          giới thiệu đến quý khách hàng phần mềm quản lý dịch vụ quảng cáo
          chuyên nghiệp, hiệu quả và toàn diện. Được thiết kế đặc biệt dành cho
          các đài phát thanh và truyền hình, phần mềm này hứa hẹn mang lại sự
          tiện lợi và tối ưu hóa quy trình quản lý quảng cáo từ A đến Z.
        </div>

        <div>
          <strong>1. Quản lý người dùng và phân quyền</strong>
        </div>

        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;Phần mềm cho phép theo dõi và quản lý chi tiết
          thông tin người dùng. Với tính năng phân quyền truy cập theo từng chức
          năng, đảm bảo mọi hoạt động trong hệ thống được kiểm soát chặt chẽ và
          an toàn.
        </div>

        <img
          src={CTV1}
          alt=""
          className="my-2"
          style={{ height: "100%", width: "100%" }}
        />

        <div>
          <strong>
            2. Quản lý thông tin đơn vị quảng cáo, thông báo, phát thanh và mạng
            xã hội
          </strong>
        </div>

        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;Lưu trữ thông tin chi tiết về các đơn vị quảng
          cáo, giúp theo dõi và quản lý thông tin thông báo, phát thanh, cho đến
          quảng cáo trên mạng xã hội, và các chiến dịch quảng cáo một cách dễ
          dàng và hiệu quả.
        </div>
        <img
          src={CTV2}
          alt=""
          className="my-2"
          style={{ height: "100%", width: "100%" }}
        />

        <div>
          <strong>3. Quản lý thông tin sản phẩm quảng cáo</strong>
        </div>

        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;Theo dõi và quản lý chi tiết thông tin về sản
          phẩm quảng cáo, từ đó hỗ trợ trong việc xây dựng chiến lược quảng cáo
          và tối ưu hóa hiệu quả truyền thông.
        </div>

        <div>
          <strong>4. Bảng giá quảng cáo</strong>
        </div>

        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;Phần mềm cung cấp công cụ quản lý bảng giá cho
          các loại quảng cáo, từ quảng cáo truyền hình, thông báo, phát thanh
          cho đến quảng cáo trên mạng xã hội, giúp người dùng dễ dàng điều chỉnh
          và cập nhật giá cả phù hợp.
        </div>

        <div>
          <strong>5. Chương trình phát sóng và khung thời gian</strong>
        </div>

        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;Quản lý lịch phát sóng và xác định khung thời
          gian phát sóng trong ngày, đảm bảo mọi chương trình và quảng cáo được
          phát sóng đúng giờ và đạt hiệu quả tối đa.
        </div>
        <img
          src={CTV3}
          alt=""
          className="my-2"
          style={{ height: "100%", width: "100%" }}
        />
        <div>
          <strong>6. Phụ lục và thông tin hợp đồng</strong>
        </div>

        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;Lưu trữ và quản lý thông tin hợp đồng và các
          phụ lục kèm theo, giúp việc tra cứu và quản lý hợp đồng quảng cáo trở
          nên nhanh chóng và chính xác.
        </div>

        <div>
          <strong>7. Báo cáo thống kê</strong>
        </div>

        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;Cung cấp các báo cáo chi tiết về lịch phát
          sóng, hợp đồng, và doanh thu theo ngày, tuần, tháng, quý, năm. Ngoài
          ra, người dùng có thể in lịch phát sóng, hợp đồng và báo cáo theo nhu
          cầu, giúp việc quản lý và phân tích dữ liệu trở nên dễ dàng và hiệu
          quả hơn.
        </div>

        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;Phần mềm quản lý dịch vụ quảng cáo của Katec
          không chỉ giúp tối ưu hóa quy trình làm việc mà còn nâng cao hiệu quả
          quản lý, tiết kiệm thời gian và chi phí cho các đài phát thanh và
          truyền hình. Hãy liên hệ với chúng tôi ngay hôm nay để trải nghiệm
          những tính năng ưu việt và giải pháp quản lý toàn diện mà phần mềm
          mang lại
        </div>
      </div>
    </Container>
  );
};

export default CTV;
