import React, { Children } from "react";
import { Link } from "react-router-dom";
import Icon from "../../components/Icon";
import {
	INTRODUCTION,
	PRODUCT,
	// SOLUTION,
	MEDIA,
	CONTACT,
	TOPIC,
} from "../../routes/routerUrl";

import SidebarItem from "./SidebarItem";

const navbarItems = [
	{
		id: 1,
		title: "Trang chủ",
		link: "/",
	},
	{
		id: 2,
		title: "Giới thiệu",
		link: INTRODUCTION,
		/*children: [
			{
				id: "2-1",
				title: "Lịch sử",
				link: INTRODUCTION,
			},
			{
				id: "2-2",
				title: "Đội ngũ lãnh đạo",
				link: INTRODUCTION,
			},
		],*/
	},
	{
		id: 3,
		title: "Sản phẩm",
		link: PRODUCT,
	},
	/*{
		id: 4,
		title: "Giải pháp",
		link: SOLUTION,
	},*/
	{
		id: 5,
		title: "Tin tức",
		link: TOPIC,
	},
	{
		id: 6,
		title: "Tuyển dụng",
		link: MEDIA,
	},
	{
		id: 7,
		title: "Liên hệ",
		link: CONTACT,
	},
];

const Sidebar = ({ setShowSideBar, showSideBar, shop }) => {
	return (
		<nav
			style={{
				fontSize: "0.9rem",
			}}
			className={`${
				showSideBar ? "sidebar-menu active" : "sidebar-menu"
			} p-4`}
		>
			<div className="pb-3 my-3 border-bottom">
				{navbarItems?.map((item) => {
					return (
						<SidebarItem item={item} setShowSideBar={setShowSideBar} />
					);
				})}
			</div>

			<div className="mt-4">
				<div className="mb-2">
					<Icon icon="phone" /> Số điện thoại: {/*shop.shopPhone*/} {"0889881010"}
					<div>
						<Icon icon="envelope" /> Email: {/*shop.shopEmail*/} {"katec.cantho@gmail.com"}
					</div>
				</div>
			</div>

			<div
				style={{ marginLeft: "-0.8rem" }}
				className="d-flex justify-content-between mt-3"
			></div>
		</nav>
	);
};

export default Sidebar;
