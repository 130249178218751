import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../../utils/yupGlobal";
import { toast } from "react-toastify";

import { Button, Form, Modal } from "react-bootstrap";
import convertToSlug from "../../../utils/slugFormatter";

const TopicEditModal = ({ handleCloseModal, showModal, selectedTopic }) => {
	const [slug, setSlug] = useState("");
	const [isSendForm, setIsSendForm] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errMessage, setErrMessage] = useState({
		cateSlug: undefined,
	});

	const {
		reset,
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(Schema),
	});

	useEffect(() => {
		getDetailTopic(selectedTopic);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Api
	const getDetailTopic = (categoryId) => {
		setIsLoading(true);
		axios
			.get(`/category/show`, {
				params: {
					id: categoryId,
					isType: "cPost",
				},
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					reset({
						name: res.data.category.cateNameVi,
					});
					setSlug(res.data.category.cateSlug);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const updateNewTopic = async (data) => {
		setIsSendForm(true);

		await axios
			.put(`/category/update`, {
				id: selectedTopic,
				cateNameVi: data.name,
				cateSlug: slug,
				isType: "cPost",
				cateParentId: process.env.REACT_APP_TOPIC_ID,
				isStatus: "S1",
			})
			.then((res) => {
				setIsSendForm(false);

				if (res.data.errCode === 0) {
					toast(res.data.message, {
						type: "success",
						autoClose: 1000,
					});
					handleCloseModal(true);
				} else {
					setErrMessage(res.data.message);
					toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	return (
		<Modal
			show={showModal}
			onHide={() => handleCloseModal(false)}
			backdrop="static"
		>
			<Modal.Header closeButton={!isSendForm}>
				<Modal.Title>Cập nhật chủ đề</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form
					id="edit-topic-hook-form"
					onSubmit={handleSubmit((data) => {
						updateNewTopic(data);
					})}
					className="mb-5"
				>
					<div className="mb-3">
						<Form.Floating>
							<Form.Control
								type="text"
								placeholder={`Tên chủ đề`}
								name={`name`}
								{...register(`name`, {
									onChange: () => {
										setSlug(
											convertToSlug(getValues("name")),
										);
									},
								})}
							/>

							<Form.Label>
								{`Tên chủ đề`}
								<i className="text-danger">*</i>
							</Form.Label>
						</Form.Floating>

						{errors[`name`] && (
							<p
								style={{
									fontSize: 13,
									color: "red",
									marginTop: 6,
								}}
							>
								{errors[`name`].message}
							</p>
						)}
					</div>

					<div>
						<Form.Floating>
							<Form.Control
								type="text"
								placeholder="Slug"
								name="slug"
								disabled
								value={slug}
							/>

							<Form.Label>
								Slug
								<i className="text-danger">*</i>
							</Form.Label>
						</Form.Floating>
						{errMessage.cateSlug && (
							<p
								style={{
									fontSize: 13,
									color: "red",
									marginTop: 6,
								}}
							>
								{errMessage.cateSlug}
							</p>
						)}
					</div>
				</Form>
			</Modal.Body>

			<Modal.Footer>
				<Button
					type="button"
					variant="secondary"
					onClick={() => handleCloseModal(false)}
					disabled={isSendForm}
				>
					Đóng
				</Button>
				<Button
					type="submit"
					form="edit-topic-hook-form"
					variant="primary"
					disabled={isSendForm || isLoading}
				>
					Cập nhật
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

const Schema = yup.object().shape({
	name: yup
		.string()
		.required("Không được bỏ trống")
		.test(
			"len",
			"Tên chủ đề không dài quá 255 kí tự",
			(val) => val.length >= 0 && val.length <= 255,
		),
});

export default TopicEditModal;
