import axios from "axios";
import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import TextEditor from "../../components/TextEditor";

import Khen from "../../assets/image/Hình ảnh_Bằng khen Hội thi sáng tạo.jpg";
import Banner from "../../assets/image/giay_chung_nhan_KHCN.jpg";
import GT1 from "../../assets/image/GT1.jpg";
import GT2 from "../../assets/image/giay_chung_nhan_KHCN.jpg";
const Introduction = () => {
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "Giới thiệu";
  }, []);
  const Section = ({ title, description, children }) => {
    return (
      <div style={{ margin: "0rem 0rem" }}>
        <div className="mb-2">
          <div className="title-line" />
          <div className="d-flex justify-content-center">
            <div
              className="title-text px-2 px-md-5"
              style={{
                width: "fit-content",
                backgroundColor: "white",
                marginTop: "-1.4rem",
                textTransform: "uppercase",
              }}
            >
              {title}
            </div>
          </div>
          <div
            className="d-flex justify-content-center"
            style={{
              fontSize: "18px",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            {description}
          </div>
        </div>
        {children}
      </div>
    );
  };
  return (
    <Container className="container-wrapper my-5">
      <Section title="katec giới thiệu"></Section>

      <div className="px-3">
        <Row>
          <Col md={4}>
            <p style={{ fontSize: "1.2rem", textAlign: "justify" }}>
              &nbsp; &nbsp; <strong className="text-primary"> KATEC </strong> là
              công ty chuyên về lĩnh vực Công nghệ thông tin tại Thành phố Cần
              Thơ. Với đội ngũ lập trình là các kỹ sư CNTT có kinh nghiệm, đã
              qua thực chiến làm ra các phần mềm cung cấp trên thị trường.{" "}
              <span className="text-primary fw_8">
                Là doanh nghiệp khoa học công nghệ Thành phố Cần Thơ{" "}
              </span>
              với nhiều năm kinh nghiệm trong lĩnh vực sản xuất phần mềm, chúng
              tôi tự hào là một trong những đơn vị tại Thành phố Cần Thơ uy tín
              và chuyên nghiệp trong ngành Công nghệ thông tin hiện nay.{" "}
              <br></br>
              <br></br> &nbsp; &nbsp; Chúng tôi luôn luôn hướng tới sự sáng tạo
              và đổi mới, để đảm bảo rằng khách hàng luôn nhận được giải pháp
              tốt nhất cho mục tiêu hoạt động của mình
            </p>
          </Col>
          <Col md={8}>
            <Row>
              <img
                src={GT1}
                alt=""
                className="custom-image"
                style={{ width: "100%", aspectRatio: "8/5" }}
              />
            </Row>
            <p style={{ fontSize: "1.15rem", textAlign: "justify" }}>
              <center>
                {" "}
                <strong className="text-danger text-center ">
                  {" "}
                  Kết quả đạt được{" "}
                </strong>
              </center>
              - Giải nhì cuộc thi sáng tạo kỹ thuật lần thứ 12 năm 2022-2023
              Thành phố Cần Thơ. <br></br> - Được các trường mầm non, nhóm trẻ
              đánh giá là phần mềm mang lại nhiều tiện ích, hiệu quả về kinh tế
              trong quản lý.{" "}
              <a
                href="https://canthotv.vn/quan-ly-suat-an-va-dinh-luong-dinh-duong-cho-tre/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span style={{ fontSize: "1.2rem" }}>
                  Được phát sóng trên truyền hình thành phố Cần Thơ.
                </span>
              </a>
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={7}>
            <Row>
              <img
                src={Khen}
                alt=""
                className="custom-image"
                style={{ height: "100%", width: "100%" }}
              />
            </Row>
            <p style={{ fontSize: "1.15rem", textAlign: "justify" }}>
              <span className="text-primary fw_8">
                {" "}
                Chăm sóc khách hàng 24/7
              </span>{" "}
              qua điện thoại hotline, zalo, nhóm và kết nối trực tiếp qua
              ultraview trên toàn quốc.
            </p>
          </Col>
          <Col md={5}>
            <img
              src={Banner}
              alt=""
              className="custom-image"
              style={{ height: "100%", width: "100%" }}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Introduction;
