import { Card } from "react-bootstrap";
import "./Style.css";
import { Link } from "react-router-dom";
import Icon from "./Icon";
import Loading from "./Loading";
import NoData from "./NoData";
import ImageURL from "./ImageURL";
import DateFormat from "./DateFormat";
import MyPagination from "./MyPagination";

const ListNew = ({
	isLoading,
	posts,
	itemsCount,
	itemsPerPage,
	currentPage,
	setCurrentPage,
	isPagiantion,
}) => {
	return (
		<>
			{isLoading ? (
				<Loading />
			) : posts?.length > 0 ? (
				posts?.map((post) => {
					return (
						<Card key={post.id} className="border-0 mb-4">
							<Link to={`${post.postSlug}`} className="text-link">
								<h3>{post.postTitleVi}</h3>
							</Link>

							<Link to={`${post.postSlug}`} className="my-4">
								<ImageURL
									style={{
										aspectRatio: "900/600",
										backgroundSize: "cover",
									}}
									imageURL={post.imgURL}
								/>
							</Link>

							<Card.Text>{post.postDescVi}</Card.Text>

							<div className="new-border d-flex justify-content-between p-2">
								<DateFormat date={post.createdAt} />
								<Link
									to={`${post.postSlug}`}
									className="text-link"
								>
									<Icon icon="hand-point-right" /> Xem thêm
								</Link>
							</div>
						</Card>
					);
				})
			) : (
				<NoData />
			)}

			{isPagiantion && (
				<MyPagination
					itemsCount={itemsCount}
					itemsPerPage={itemsPerPage}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					alwaysShown={false}
				/>
			)}
		</>
	);
};

export default ListNew;
