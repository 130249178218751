import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Col, Container, Row, Tab, Tabs, Breadcrumb } from "react-bootstrap";
import Carousel from "react-multi-carousel";

import ListProduct from "../../../components/ListProduct";
import ImageURL from "../../../components/ImageURL";
import TextEditor from "../../../components/TextEditor";

const responsiveProduct = {
	desktop: {
		breakpoint: { max: 3000, min: 0 },
		items: 1,
		slidesToSlide: 1,
	},
};

const ProductDetail = () => {
	let { slug } = useParams();

	const [product, setProduct] = useState({ imgPaths: [] });
	const [relatedProducts, setRelatedProducts] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		getProduct(slug);
	}, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getProduct = async (slug) => {
		setIsLoading(true);

		await axios
			.get(`/product/list`, {
				params: {
					proSlug: slug,
				},
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					document.title = res.data.product.proNameVi;

					const newProduct = res.data.product;
					newProduct.imgPaths = res.data.product.imgPaths.split(",");

					setProduct(newProduct);

					getAllProducts(newProduct.categoryId, newProduct.id);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllProducts = async (cateId, productId) => {
		await axios
			.get(`/product/list`, {
				params: {
					cateId: cateId || 0,
					limit: 11,
				},
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setRelatedProducts(
						res.data.products
							.filter((product) => product.id !== productId)
							.map((product) => {
								return {
									...product,
									imgPaths: product.imgPaths ? product.imgPaths.split(",") : [],
								};
							}),
					);
				} else {
					toast(res.data.message, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	return (
		<Container className="container-wrapper mb-5">
			<Breadcrumb
				style={{
					backgroundColor: "var(--mute-bg-color)",
				}}
				className="p-3 pb-1"
			>
				<Breadcrumb.Item active>Trang chủ</Breadcrumb.Item>
				<Breadcrumb.Item active>Loại sản phẩm</Breadcrumb.Item>
				<Breadcrumb.Item active>{product.proNameVi}</Breadcrumb.Item>
			</Breadcrumb>

			<Row className="mb-5">
				<Col xs="12" md="6">
					<Carousel responsive={responsiveProduct} infinite={true}>
						{product.imgPaths.map((image, index) => {
							return <ImageURL key={index} style={{ height: "30rem" }} imageURL={image} />;
						})}
					</Carousel>
				</Col>
				<Col xs="12" md="6" className="pt-5">
					<h1
						style={{
							fontWeight: "500",
							color: "var(--secondary-title-color)",
						}}
					>
						{product.proNameVi}
					</h1>

					<div>{product.proDescVi}</div>
				</Col>
			</Row>

			<Tabs defaultActiveKey="formula" style={{ borderColor: "var(--sencondary-color)" }}>
				<Tab tabClassName="text-black" eventKey="formula" title={<div className="fw-bold">CÔNG THỨC</div>} className="tab-product-border p-3">
					<div>{product.proInfoVi ? <TextEditor disable hideToolbar={true} setContents={product.proInfoVi} onChange={() => {}} /> : ""}</div>
				</Tab>
				<Tab tabClassName="text-black" eventKey="use" title={<div className="fw-bold">CÔNG DỤNG</div>} className="tab-product-border p-3">
					<div>{product.proInfoEn ? <TextEditor disable hideToolbar={true} setContents={product.proInfoEn} onChange={() => {}} /> : ""}</div>
				</Tab>
				<Tab
					tabClassName="text-black"
					eventKey="feature"
					title={<div className="fw-bold">ĐẶC ĐIỂM VÀ TÍNH NĂNG</div>}
					className="tab-product-border p-3"
				>
					<div>{product.proContentVi ? <TextEditor disable hideToolbar={true} setContents={product.proContentVi} onChange={() => {}} /> : ""}</div>
				</Tab>
				<Tab tabClassName="text-black" eventKey="guide" title={<div className="fw-bold">HƯỚNG DẪN SỬ DỤNG</div>} className="tab-product-border p-3">
					<div>{product.proContentEn ? <TextEditor disable hideToolbar={true} setContents={product.proContentEn} onChange={() => {}} /> : ""}</div>
				</Tab>
			</Tabs>

			<div className="mt-5">
				<h3 className="mb-3 fst-italic">Xem thêm các sản phẩm</h3>

				<ListProduct showRow={4} products={relatedProducts} isLoading={isLoading} isPagiantion={false} />
			</div>
		</Container>
	);
};

export default ProductDetail;
